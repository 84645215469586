import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDebounce } from "libs/Hooks";
import axios from "../../../axios";
import { useGroup } from "GroupSavings/hooks/data/useGroup";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import correct from "assets/icons/correct.svg";

const formSchema = Yup.object().shape({
  saverId: Yup.string().required("Saver ID is required"),
  amount: Yup.number()
    .required("Transaction amount is required")
    .min(1000, "Transaction amount must be at least NGN1000")
    .max(500_000, "Transaction amount must not be greater than NGN500,000"),
  pin: Yup.string()
    .required("Transaction pin is required")
    .min(4, "Transaction pin must be 4 digits")
    .max(4, "Transaction pin must be 4 digits"),
});

const Saving = ({ show, toggleSaving }) => {
  const [loading, setLoading] = useState(false);
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  let params = useParams();

  const [currentSaver, setCurrentSaver] = useState(null);

  useDebounce(() => verifyAccountId(), 1000, [watch("saverId")]);

  const verifyAccountId = async () => {
    if (watch("saverId")?.length < 8) return;

    setLoading(true);
    const saverIdInputEl = document.getElementById("saverId");

    try {
      const response = await axios.get(`/saver/saver/${watch("saverId")}`);
      saverIdInputEl.classList.remove("border-red-500");
      saverIdInputEl.classList.add("border-green-500");
      setCurrentSaver(response.data.data);
      setLoading(false);
    } catch (error) {
      saverIdInputEl.classList.remove("border-green-500");
      saverIdInputEl.classList.add("border-red-500");
      setCurrentSaver(null);
      setLoading(false);
    }
  };

  const { group, refetch } = useGroup(params.id);

  const handleSaving = async (values) => {
    const { saverId, pin, amount } = values;

    try {
      setLoading(true);
      await axios.post(`/savings-group/save/${params.id}`, {
        amount: parseInt(amount, 10),
        saverId,
        pin,
      });
      toast.success("Successfully saved!");
      refetch();
      setLoading(false);
      // setDisable(true);
      toggleSaving();
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <Transition appear as={Fragment} show={show}>
      <Dialog
        className="fixed inset-0 z-50 overflow-y-auto text-gray-800"
        onClose={toggleSaving}
      >
        <div className="flex items-center justify-center h-screen p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>
          <span aria-hidden="true" className="inline-block align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block p-10 my-10 space-y-5 overflow-hidden text-left align-middle transition-all transform bg-white rounded shadow-xl w-4/5 md:w-2/5 md:min-w-80">
              <Dialog.Title
                as="h2"
                className="text-xl font-medium leading-5 text-left"
              >
                Savings
              </Dialog.Title>
              <form className="mt-6" onSubmit={handleSubmit(handleSaving)}>
                <div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="saverId"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Saver ID
                    </label>
                    <input
                      type="text"
                      className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Saver ID"
                      style={{ transition: "all .15s ease" }}
                      id="saverId"
                      {...register("saverId", { required: true })}
                    />
                    {currentSaver && (
                      <div className="flex justify-between mt-2">
                        <small className="text-xs text-green-600">
                          {`${currentSaver.firstName} ${currentSaver.lastName}`}
                        </small>
                        <img src={correct} alt="..." />
                      </div>
                    )}
                    {errors?.saverId ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.saverId?.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="amount"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Min (NGN 1000)"
                      style={{ transition: "all .15s ease" }}
                      id="amount"
                      defaultValue={group?.amount || 1000}
                      {...register("amount", { required: true })}
                    />
                    {errors?.amount ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.amount?.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="relative w-full">
                    <label
                      htmlFor="pin"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Transaction Pin
                    </label>
                    <input
                      type="text"
                      className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="****"
                      style={{ transition: "all .15s ease" }}
                      id="pin"
                      {...register("pin", { required: true })}
                    />
                    {errors?.pin ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.pin?.message}
                      </p>
                    ) : null}
                  </div>
                  {/* <div className="flex items-center mt-3">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      I am paying for this saver
                    </label>
                  </div> */}
                </div>
                <div className="mt-6 flex items-center justify-center">
                  <button
                    className={
                      "bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                      (loading && "bg-opacity-30")
                    }
                    disabled={loading}
                  >
                    {loading ? "..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Saving;

import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthContext from "context/AuthContext";
import axios from "../../axios";

import toast from "react-hot-toast";
import SpinnerIcon from "components/Icons/Spinner";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import useTitle from "hooks/useTitle";

const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at 6 char long"),
});

export default function Login() {
  useTitle("Adashi - Login");

  const formOptions = { resolver: yupResolver(loginFormSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const { loginUser, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPasswd = () => setShowPassword(!showPassword);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      if (user.email_verified_at === null) {
        navigate("/auth/verify-email");
      } else {
        navigate("/dashboard");
      }
    }
  });

  const handleLogin = async (values) => {
    try {
      setLoading(true);
      const { email, password } = values;
      const response = await axios.post("/auth/login", { email, password });

      loginUser(response.data.user, response.data.accessToken);
      toast.success("Login successful");
      setLoading(false);

      if (response.data.user.email_verified_at === null) {
        navigate("/auth/verify-email");
      } else {
        if (location.state?.from) {
          navigate(location.state.from.pathname);
        } else {
          navigate("/dashboard");
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      if (err.response) toast.error(err.response?.data?.message);
    }
  };

  return (
    <div className="bg-light min-h-screen grid place-content-center">
      <div className="text-center w-screen md:w-auto">
        <h1 className="text-3xl text-primary mb-7">Adashi.ng</h1>
        <p className="text-sm text-dark">
          Welcome back, sign in to access dashboard
        </p>

        <form
          onSubmit={handleSubmit(handleLogin)}
          className="login-box max-w-xl md:w-[34rem] mx-auto mt-5 px-4 md:px-0"
        >
          <div className="mb-6">
            <label
              htmlFor="email"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Email address
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
              placeholder="e.g example@email.com"
              {...register("email", { required: true })}
            />
            {errors?.email && (
              <p className="text-left text-red-600 text-xs mt-1">
                {errors?.email?.message}
              </p>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="password"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full py-1 px-3 focus:outline-none focus:ring h-12"
                {...register("password", { required: true, minLength: 6 })}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={toggleShowPasswd}
              >
                {showPassword ? (
                  <EyeOffIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            {errors?.password && (
              <p className="text-left text-red-600 text-xs mt-1">
                {errors?.password?.message}
              </p>
            )}
          </div>
          <div className="text-left mb-7">
            <Link to="/auth/forgot-password" className="text-blueText text-sm">
              Forgot Your Password?
            </Link>
          </div>

          <button
            type="submit"
            className={
              "py-1 border-primary border w-full text-center bg-primary text-white text-sm h-12 flex justify-center items-center " +
              (loading && "bg-opacity-50")
            }
            disabled={loading}
          >
            {loading ? <SpinnerIcon /> : "Login in to your account"}
          </button>
          <p className="text-dark opacity-80 mt-4 text-sm">
            New to Adashi.ng
            <Link
              to="/auth/signup"
              className="text-primary ml-2 opacity-100 text-sm"
            >
              Sign up
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

import { useSavers } from "hooks/data/useSavers";
import React, { Fragment, useEffect, useState } from "react";
import axios from "../../../axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useGroup } from "GroupSavings/hooks/data/useGroup";

const SaverItem = ({ isChecked, addSaver, removeSaver, saver }) => {
  return (
    <tr className="hover:bg-light cursor-pointer">
      <th className="border-t-0 px-3 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm text-left font-normal">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
          checked={isChecked}
          onChange={(e) => {
            if (!isChecked) {
              addSaver(saver._id);
            } else {
              removeSaver(saver._id);
            }
          }}
        />
      </th>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
        {saver?.saverId}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
        {saver?.firstName} {saver?.lastName}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
        0{saver?.phone}
      </td>
    </tr>
  );
};

const GroupSaversAdd = ({ show, toggleSavers, members }) => {
  const [selectedSaversId, setSelectedSaversId] = useState([]);
  const [loading, setLoading] = useState(false);

  const { savers, isLoading } = useSavers();

  let params = useParams();

  const { refetch } = useGroup(params.id);

  useEffect(() => {
    const selectedSaverIds = members?.map((member) => member._id);
    setSelectedSaversId(selectedSaverIds);
  }, [members]);

  function isSelected(saverId) {
    return selectedSaversId?.find((id) => id === saverId);
  }

  async function addSaver(saverId) {
    try {
      setLoading(true);
      await axios.post(`/savings-group/add-member/${params.id}`, {
        saverId,
      });
      setLoading(false);
      setSelectedSaversId([...selectedSaversId, saverId]);
      toast.success("Saver added successfully");
      refetch();
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.error(err);
      setLoading(false);
    }
  }

  async function removeSaver(saverId) {
    try {
      setLoading(true);
      await axios.post(`/savings-group/remove-member/${params.id}`, {
        saverId,
      });
      const dupSavers = [...selectedSaversId];
      const newSavers = dupSavers.filter((id) => id !== saverId);
      setSelectedSaversId(newSavers);
      setLoading(false);
      toast.success("Saver removed successfully");
      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <>
      {show ? (
        <Fragment>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-h-[80vh] max-w-xl md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid border-borderbg rounded-t">
                  <h3 className="text-base font-medium text-blackcolor leading-5">
                    Savers list
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={toggleSavers}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-y-scroll h-[300px]">
                  <div className="w-full mb-12 xl:mb-0">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
                      <div className="block w-full overflow-x-auto">
                        <table className="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th className="px-3 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left"></th>
                              <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                                Saver ID
                              </th>
                              <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                                Name
                              </th>
                              <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                                Phone
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {savers.length === 0 ? (
                              <tr className="h-12 min-w-full flex items-center">
                                <td className="text-center w-full min-w-full absolute h-14 flex items-center justify-center bg-light p-0">
                                  <span className="">No Saver Found</span>
                                </td>
                              </tr>
                            ) : (
                              savers?.map((value, index) => {
                                const selected = isSelected(value._id);

                                return (
                                  <SaverItem
                                    key={`item-${index}`}
                                    index={index}
                                    saver={value}
                                    isChecked={selected}
                                    addSaver={addSaver}
                                    removeSaver={removeSaver}
                                  />
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex p-6 border-t border-solid rounded-b justify-center"></div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
      ) : null}
    </>
  );
};

export default GroupSaversAdd;

import React from "react";
import MainNavigation from "components/Navbars/MainNavigation";
import MainFooter from "components/Footer/MainFooter";

export default function Disclaimer() {
  return (
    <div>
      <MainNavigation />

      <section className="my-4 py-3 md:py-4 px-4 md:px-20 md:mt-20">
        <div className="container mx-auto max-w-[1280px]">
          <h2 className="text-2xl md:text-3xl text-blackText font-semibold mb-10">
            Disclaimer and more
          </h2>

          <div className="mb-3">
            <h3 className="text-xl text-primary mb-4 font-medium">
              Disclaimers,
            </h3>
            <p className="mb-5 opacity-80">
              The information, software, products and services included in or
              available through Adashi Services may include interruptions,
              inconsistencies, errors, inaccuracies or typographical errors.
              Changes are periodically made to the ENOVATE LAB Services and to
              the information therein. ENOVATE LAB and/or its respective
              suppliers may make improvements and/or changes in Adashi Services
              at any time. Information and advice stated or received via Adashi
              Services should not be relied upon for personal, medical, legal or
              financial decisions and you should consult an appropriate
              professional for specific advice tailored to your situation.
            </p>
            <p className="mb-5 opacity-80">
              ENOVATE LAB and/or its respective suppliers make no
              representations and warranties about the suitability, reliability,
              availability, timeliness, lack of viruses or other harmful
              components and accuracy of the information, software, products,
              services and related graphics contained within Adashi Services for
              any purpose. All such information, software, products, services
              and related graphics are provided "as is" without warranty of any
              kind. ENOVATE LAB and/or its respective suppliers hereby disclaim
              all warranties and conditions with regard to this information,
              software, products, services and related graphics, including all
              implied warranties and conditions of merchantability, fitness for
              a particular purpose, workmanlike effort, title and non-
              infringement.
            </p>
            <p className="mb-5 opacity-80">
              You specifically agree that ENOVATE LAB shall not be responsible
              for unauthorized access to or alteration of your transmissions or
              data, any material or data sent or received or not sent or
              received, or any transactions entered into through a Adashi
              service. In no event shall ENOVATE LAB and/or its suppliers be
              liable for any direct, indirect, punitive, incidental, special,
              consequential damages or any damages whatsoever including, without
              limitation, damages for loss of use, data or profits, arising out
              of or in any way connected with the use or performance of Adashi
              Services, with the delay or inability to use Adashi Services or
              related services, the provision of or failure to provide services,
              or for any information, software, products, services and related
              graphics obtained through Adashi Services, or otherwise arising
              out of the use of Adashi Services, whether based on contract,
              tort, negligence, strict liability or otherwise, even if ENOVATE
              LAB or any of its suppliers has been advised of the possibility of
              damages. ENOVATE LAB does not endorse in anyway any
              advertisers/contents of advertisers on its mobile application or
              web apps. Please therefore verify the veracity of all information
              on your own before undertaking reliance and acting thereupon.
              ENOVATE LAB shall not be responsible nor liable for any
              consequential damages arising on account of your relying on the
              contents of the advertisement.
            </p>
            <p className="mb-5 opacity-80">
              This agreement is governed by the Laws of the Republic of Nigeria.
              You hereby irrevocably consent to the exclusive jurisdiction and
              venue of Courts in Nigeria in all disputes arising out of or
              relating to the use of Adashi Services. Use of Adashi Services is
              unauthorized in any jurisdiction that does not give effect to all
              provisions of these terms and conditions, including without
              limitation this paragraph. You agree to indemnify and hold ENOVATE
              LAB, its subsidiaries, affiliates, officers and employees,
              harmless from any claim, demand, or damage, including reasonable
              attorneys' fees, asserted by any third party due to or arising out
              of your use of or conduct on Adashi Services. ENOVATE LAB reserves
              the right to disclose any personal information about you or your
              use of Adashi Services, including but not limited to its contents,
              contact information, account numbers, mobile number, meter number,
              usage activity, transaction history and device information,
              without your prior permission if ENOVATE LAB has a good faith
              belief that such action is necessary to: (1) conform to legal
              requirements or comply with legal process; (2) protect and defend
              the rights or property of ENOVATE LAB or its affiliated companies;
              (3) enforce the terms or use; or (4) act to protect the interests
              of its members or others. ENOVATE LAB's performance of this
              agreement is subject to existing laws and legal process, and
              nothing contained in this agreement is in derogation of ENOVATE
              LAB's right to comply with governmental, court and law enforcement
              requests or requirements relating to your use of Adashi Services
              or information provided to or gathered by ENOVATE LAB with respect
              to such use. If any part of this agreement is determined to be
              invalid or unenforceable pursuant to applicable law including, but
              not limited to, the warranty disclaimers and liability limitations
              set forth above, then the invalid or unenforceable provision will
              be deemed superseded by a valid, enforceable provision that most
              closely matches the intent of the original provision and the
              remainder of the agreement shall continue in effect. Unless
              otherwise specified herein, this agreement constitutes the entire
              agreement between you the User and ENOVATE LAB with respect to
              Adashi Services and it supersedes all prior or contemporaneous
              communications and proposals, whether electronic, oral or written,
              between the user and ENOVATE LAB with respect to Adashi Services.
            </p>
          </div>
          <div className="mb-3">
            <h3 className="text-xl text-primary mb-4 font-medium">
              Dispute Resolution – Arbitration
            </h3>
            <p className="mb-5 opacity-80">
              ENOVATE LAB may elect to resolve any controversy or claim arising
              out of or relating to these Terms by binding arbitration in
              accordance with the provisions of the Arbitration and Conciliation
              Act, Chapter 19 of the Laws of the Federation of Nigeria 1990. Any
              such controversy or claim shall be arbitrated on an individual
              basis and shall not be consolidated in any arbitration with any
              claim or controversy of any other party. The arbitration shall be
              conducted in Abuja, Nigeria and judgment on the arbitration award
              may be entered in any court having jurisdiction thereof. Either
              you or ENOVATE LAB may seek any interim or preliminary relief from
              a court of competent jurisdiction necessary to protect the rights
              or the property of you or ENOVATE LAB, (or its agents, suppliers,
              and subcontractors), pending the completion of arbitration. The
              language of Arbitration shall be English.
            </p>
          </div>
          <div className="mb-2">
            <h3 className="text-xl text-primary mb-4 font-medium">
              Miscellaneous
            </h3>
            <p className="mb-5 opacity-80">
              ENOVATE LAB may be required under any legislation, to notify you
              of certain events. You hereby acknowledge and consent that such
              notices will be effective upon its posting them on its website,
              social media platforms or delivering them to you through e-mail
              and/or SMS. If you do not provide ENOVATE LAB with accurate
              information, ENOVATE LAB cannot be held liable if ENOVATE LAB fail
              to notify you. You agree that regardless of any statute or law to
              the contrary, any claim or cause of action arising out of or
              related to these Terms must be filed within one (1) month after
              such claim or cause of action arose or be forever barred.
            </p>
            <p className="mb-5 opacity-80">
              These Terms, including all terms, conditions, and policies that
              are incorporated into these terms by reference, constitute the
              entire agreement between you and ENOVATE LAB, and govern your use
              of Adashi Services, superseding any prior agreements that you may
              have with ENOVATE LAB.
            </p>
          </div>
        </div>
      </section>

      <MainFooter />
    </div>
  );
}

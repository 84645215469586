import React, { useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import axios from "../../axios";

import { useDebounce } from "libs/Hooks";
import { addBankAccount } from "../../apicore";
import { useNigBanks } from "hooks/data/useNigBanks";
import toast from "react-hot-toast";
import { useUser } from "hooks/data/useUser";

export default function AddBankAccount(props) {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const [bankDetails, setBankDetails] = useState(null);

  const { refetch } = useUser();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { data, banksLoading } = useNigBanks();

  const banks = banksLoading ? [] : data?.banks;

  const BankSelect = React.forwardRef(
    ({ onChange, onBlur, name, label, defaultValue }, ref) => (
      <>
        <label
          htmlFor="bankName"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          {label}
        </label>
        <select
          className="border border-secondary_sky_base px-3 py-2 placeholder-grey_80 text-grey_40 bg-white focus:outline-none focus:ring w-full rounded-lg"
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
        >
          <option />
          {banks?.map((bank) => (
            <option key={bank.bankCode} value={JSON.stringify(bank)}>
              {bank.bankName}
            </option>
          ))}
          <style jsx>{`
            select {
              padding: 1rem;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23090A0A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ")
                97% / 3% no-repeat;
            }
          `}</style>
        </select>
      </>
    )
  );
  BankSelect.displayName = "BankSelect";

  useDebounce(() => resolveAccount(), 1000, [watch("accountNumber")]);

  const resolveAccount = async () => {
    if (watch("accountNumber").length === 10) {
      try {
        setLoading(true);
        const response = await axios.post("/wallet/resolve-bank", {
          bankCode: JSON.parse(watch("bank")).bankCode,
          accountNumber: watch("accountNumber"),
        });

        document
          .getElementById("accountNumber")
          .classList.remove("border-red-500");
        document
          .getElementById("accountNumber")
          .classList.add("border-green-500");
        setBankDetails(response.data.data.details);

        setLoading(false);
        setDisable(false);
      } catch (error) {
        if (document.getElementById("accountId")) {
          document
            .getElementById("accountNumber")
            .classList.remove("border-green-500");
          document
            .getElementById("accountNumber")
            .classList.add("border-red-500");
        }

        setLoading(false);
        setDisable(true);
      }
    }
  };

  const mutation = useMutation(addBankAccount, {
    onSuccess: (res) => {
      toast.success(res.message);
      refetch();
      props.toggleModal();
    },
    onError: (error) => {
      console.log(error);
      toast.error(error.response.data.message);
    },
  });

  const { mutate, isLoading } = mutation;

  const handleAddBank = async (values) => {
    const { bank, accountNumber } = values;
    const { bankCode, bankName } = JSON.parse(bank);
    mutate({
      bankLogo: "https://nigerianbanks.xyz/logo/default-image.png",
      bankCode,
      bankName,
      accountNumber,
      accountName: bankDetails.BeneficiaryName,
    });
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <form onSubmit={handleSubmit(handleAddBank)}>
                  {/*header*/}
                  <div className="flex items-center justify-between py-4 px-6 border-b border-solid rounded-t">
                    <h3 className="text-base font-medium leading-5">
                      Bank Account
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={props.toggleModal}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="relative md:w-full">
                      <BankSelect
                        label="Bank Name"
                        {...register("bank", { required: true })}
                      />
                      {errors.bank?.type === "required" && (
                        <p className="text-left text-red-600 text-xs mt-1">
                          Bank name is required
                        </p>
                      )}
                    </div>
                    <div className="relative w-full my-3">
                      <label
                        htmlFor="accountNumber"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Account Number
                      </label>
                      <input
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Account Number"
                        style={{ transition: "all .15s ease" }}
                        name="accountNumber"
                        id="accountNumber"
                        {...register("accountNumber", { required: true })}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="accountName"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Account Name
                      </label>
                      <input
                        type="text"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Account Name"
                        style={{ transition: "all .15s ease" }}
                        name="accountName"
                        value={bankDetails?.BeneficiaryName}
                        readOnly
                        required
                      />
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex p-6 border-t border-solid rounded-b justify-center">
                    <button
                      className={
                        "bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                        (disable
                          ? "bg-opacity-70 cursor-not-allowed"
                          : "bg-opacity-100")
                      }
                      type="submit"
                      disabled={disable || loading || isLoading}
                    >
                      {loading || isLoading ? (
                        <span className="flex items-center">
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Loading...
                        </span>
                      ) : (
                        "Add Bank"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import { DashboardLayout } from "layouts";
import React, { useEffect, useState } from "react";
import { arrayMoveImmutable } from "array-move";
import GroupMembers from "./components/List/GroupMembers";
import { CashIcon, PencilAltIcon } from "@heroicons/react/outline";
import Saving from "GroupSavings/components/Modals/Saving";
import GroupSaversAdd from "GroupSavings/components/Modals/Savers";
import { useParams } from "react-router-dom";
import axios from "../axios";
import RecentTransactions from "./components/List/RecentTransactions";
import toast from "react-hot-toast";
import { useGroup } from "./hooks/data/useGroup";
import Payout from "./components/Modals/Payout";

const SavingsGroup = () => {
  const [items, setItems] = useState([]);

  const [starting, setStarting] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = useParams();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems((prevItem) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
  };

  const { group: details, isLoading, refetch } = useGroup(params.id);

  async function handleStartTenure() {
    try {
      setStarting(true);
      await axios.patch(`/savings-group/start-group/${details?._id}`);
      setStarting(false);
      toast.success("Tenure started successfully");
      refetch();
    } catch (error) {
      setStarting(false);
      console.log(error.response);
      toast.error(error?.response?.data?.message ?? "An error occurred");
    }
  }

  useEffect(() => {
    if (!isLoading && details) {
      setItems(details.members);
    }
    return () => {};
  }, [details, isLoading]);

  const [showSaversModal, setShowSaversModal] = useState(false);
  const [showSavingModal, setShowSavingModal] = useState(false);
  const [showPayoutModal, setShowPayoutModal] = useState(false);

  const toggleSavers = () => setShowSaversModal(!showSaversModal);
  const toggleSaving = () => setShowSavingModal(!showSavingModal);
  const togglePayout = () => setShowPayoutModal(!showPayoutModal);

  useEffect(() => {
    if (!params.id) return;
    (async () => {
      try {
        setLoading(true);
        const url = `/transactions/group-savings/${params.id}`;
        const { data } = await axios.get(url);
        setTransactions(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [params.id]);

  return (
    <DashboardLayout>
      <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full">
        {!isLoading && details && (
          <React.Fragment>
            <div className="mb-5">
              <h1 className="font-semibold text-dark flex items-center text-2xl">
                <span className="">{details?.name}</span>
              </h1>
            </div>

            <div className="flex flex-col space-y-6">
              <div className="px-8 py-8 bg-white rounded-2xl flex flex-col md:flex-row justify-between shadow-sm">
                <div className="flex items-center mb-4 md:mb-0">
                  <div
                    className="rounded-full p-4"
                    style={{ background: "rgba(222, 255, 229, 0.5)" }}
                  >
                    <CashIcon className="w-6 md:w-10 h-6 md:h-10 text-primary" />
                  </div>
                  <div className="ml-3">
                    <span className="block mb-2 text-sm">Group Balance</span>
                    <p className="text-2xl font-semibold">
                      ₦ {details?.balance}
                    </p>
                  </div>
                </div>
                <React.Fragment>
                  {details.status === "PENDING" ? (
                    <div className="flex items-center">
                      <button
                        className="bg-primary text-white py-2 px-3 border border-primary rounded-lg"
                        onClick={handleStartTenure}
                        disabled={starting}
                      >
                        {starting ? "Starting..." : "Start Tenure"}
                      </button>
                    </div>
                  ) : (
                    <>
                      {details.status === "COMPLETED" ? null : (
                        <div className="flex items-center space-x-3">
                          <button
                            className="bg-primary text-white py-2 px-3 border border-primary rounded-lg"
                            onClick={toggleSaving}
                          >
                            Save
                          </button>
                          <button
                            className="bg-white text-primary py-2 px-3 border border-primary rounded-lg"
                            onClick={togglePayout}
                          >
                            Payout
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </React.Fragment>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-6 pb-10 mb:pb-0">
                <div className="w-full md:w-2/3">
                  <div className="flex justify-between items-center mb-5">
                    <h3 className="font-semibold text-lg text-blackText">
                      Group Members
                    </h3>

                    {details.status === "PENDING" && (
                      <button
                        className="flex items-center"
                        onClick={toggleSavers}
                        disabled={details.status !== "PENDING"}
                      >
                        <PencilAltIcon className="w-6 h-6" />
                        <span className="ml-2">Edit Members</span>
                      </button>
                    )}
                  </div>
                  <GroupMembers
                    details={details}
                    items={items}
                    onSortEnd={onSortEnd}
                  />
                </div>
                <div className="w-full md:w-1/3">
                  <RecentTransactions transactions={transactions} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      <Saving show={showSavingModal} toggleSaving={toggleSaving} />
      {showPayoutModal && (
        <Payout show={showPayoutModal} togglePayout={togglePayout} />
      )}
      <GroupSaversAdd
        toggleSavers={toggleSavers}
        show={showSaversModal}
        members={details?.members}
      />
    </DashboardLayout>
  );
};

export default SavingsGroup;

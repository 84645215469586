import React, { useState, useEffect, Fragment, useMemo } from "react";
import axios from "../../axios";
import Pagination from "components/Pagination";

import { DashboardLayout } from "layouts";

// import FilterButton from "components/Buttons/FilterButton";
import AddSaverModal from "components/Modals/AddSaverModal";
import { ClipboardIcon, UserAddIcon } from "@heroicons/react/outline";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useSearchFilter } from "context/SearchFilterContext";
import UpdateSaverModal from "components/Modals/UpdateSaverModal";

export default function Savers() {
  const { searchQuery } = useSearchFilter();

  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  const [showModal, setModalVisibility] = useState(false);
  const toggleModal = () => setModalVisibility(!showModal);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedSaverId, setSelectedSaverId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [savers, setSavers] = useState([]);

  const saver = useMemo(
    () => savers?.find((saver) => saver._id === selectedSaverId),
    [savers, selectedSaverId]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [saversPerPage] = useState(40);

  useEffect(() => {
    const fetchSavers = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/agent/savers");
        setSavers(response.data.data.savers);
      } catch (err) {}

      setLoading(false);
    };
    fetchSavers();
  }, []);

  // Get current savers
  const indexOfLastSaver = currentPage * saversPerPage;
  const indexOfFirstSaver = indexOfLastSaver - saversPerPage;
  const currentSavers = savers.slice(indexOfFirstSaver, indexOfLastSaver);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSaverClick = (saverId) => {
    setSelectedSaverId(saverId);
    setShowUpdateModal(true);
  };

  return (
    <Fragment>
      {showModal && (
        <AddSaverModal
          savers={savers}
          setSavers={setSavers}
          show={showModal}
          setShow={setModalVisibility}
        />
      )}

      {showUpdateModal && (
        <UpdateSaverModal
          saver={saver}
          show={showUpdateModal}
          setShow={setShowUpdateModal}
          savers={savers}
          setSavers={setSavers}
        />
      )}

      <DashboardLayout>
        <div className="relative bg-light min-h-screen">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* <!-- Left: Title --> */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Savers ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                {/* <FilterButton /> */}
                {/* Add view button */}
                <button
                  className="btn bg-primary hover:bg-primary/75 text-white"
                  onClick={toggleModal}
                >
                  <UserAddIcon className="text-white h-6 w-6" />
                  <span className="hidden sm:block ml-2">Add Saver</span>
                </button>
              </div>
            </div>
          </div>

          <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full">
            {/* table */}
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
                <div className="block w-full overflow-x-auto">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                          Saver name
                        </th>
                        <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                          Identifier
                        </th>
                        <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                          Phone number
                        </th>
                        <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                          Current savings & Count(s)
                        </th>
                        <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                          Balance
                        </th>
                        <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr className="h-12 min-w-full flex items-center">
                          <td className="text-center w-full min-w-full absolute h-14 flex items-center justify-center bg-light p-0">
                            <span className="">No saver</span>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {currentSavers
                            .filter((saver) =>
                              saver.firstName.toLowerCase().includes(query)
                            )
                            .map((saver) => (
                              <tr key={saver?.saverId}>
                                <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm text-left font-normal">
                                  {saver?.firstName} {saver?.lastName}
                                </th>
                                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                                  <div className="flex items-center">
                                    <span className="mr-2 text-sm">
                                      {saver?.saverId}
                                    </span>
                                    <CopyToClipboard
                                      text={saver?.saverId}
                                      onCopy={() =>
                                        toast.success("Copied to clipboard")
                                      }
                                    >
                                      <button>
                                        <ClipboardIcon className="w-6 h-6 text-gray-900" />
                                      </button>
                                    </CopyToClipboard>
                                  </div>
                                </td>
                                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                  0{saver?.phone}
                                </td>
                                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                  {saver?.current_balance} (
                                  {saver?.current_savings_counts ?? 0})
                                </td>
                                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                  ₦ {saver?.wallet.balance}
                                </td>
                                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm text-center">
                                  <button
                                    onClick={() => handleSaverClick(saver._id)}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <Pagination
                unitsPerPage={saversPerPage}
                totalUnits={savers.length}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Fragment>
  );
}

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import axios from "../../../axios";
import { statesLgas } from "libs/States";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const OtherInfo = ({ user, refetch }) => {
  const formSchema = Yup.object().shape({
    address: Yup.string().required("Home address is required").trim(""),
    city: Yup.string().required("City is required").trim(""),
    state: Yup.string().required("State of residence is required").trim(""),
    lga: Yup.string().required("LGA of residence is required").trim(""),
    OState: Yup.string().required("State of origin is required").trim(""),
    OLga: Yup.string().required("LGA of origin is required").trim(""),
    nextName: Yup.string().required("Next of kin name is required").trim(""),
    nextPhone: Yup.string()
      .required("Phone number is required")
      .min(11, "Phone number must be at least 11 digits")
      .max(11, "Phone number must be at greater than 11 digits"),
    userCategory: Yup.string().required("User category is required").trim(""),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, watch, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedOStateName, setSelectedOStateName] = useState("");

  const selectedState = statesLgas.find((state) => {
    return state.state.name === selectedStateName;
  });
  const selectedOState = statesLgas.find((state) => {
    return state.state.name === selectedOStateName;
  });

  useEffect(() => {
    setSelectedStateName(watch("state"));
  }, [watch("state")]);
  useEffect(() => {
    setSelectedOStateName(watch("OState"));
  }, [watch("OState")]);

  const StateSelect = React.forwardRef(
    ({ onChange, onBlur, name, label, defaultValue }, ref) => (
      <>
        <label className="text-sm font-medium text-gray-900 block mb-2 text-left">
          {label}
        </label>
        <select
          className="form-select block w-full px-3 rounded border text-sm md:h-12 h-10 border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
        >
          <option />
          {statesLgas.map((state) => (
            <option
              className="text-sm"
              key={state.state.name}
              value={state.state.name}
            >
              {state.state.name}
            </option>
          ))}
        </select>
      </>
    )
  );
  const LocalSelect = React.forwardRef(
    ({ onChange, onBlur, name, label, defaultValue, lgas = [] }, ref) => (
      <>
        <label className="text-sm font-medium text-gray-900 block mb-2 text-left">
          {label}
        </label>
        <select
          className="form-select block w-full px-3 rounded border text-sm md:h-12 h-10 border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
        >
          <option className="text-sm" value={defaultValue}>
            {defaultValue}
          </option>
          {lgas.map((local) => (
            <option className="text-sm" key={local.name} value={local.name}>
              {local.name}
            </option>
          ))}
        </select>
      </>
    )
  );

  const [otherInfoLoading, setOtherInfoLoading] = useState(false);

  const otherInfoSubmit = async (values) => {
    try {
      setOtherInfoLoading(true);
      const response = await axios.patch("/agent/other-info", values);
      toast.success(response.data.message);
      setOtherInfoLoading(false);
      refetch();
    } catch (error) {
      console.log(error);
      setOtherInfoLoading(false);
      if (error.response) {
        toast.error(error.response.data);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(otherInfoSubmit)}>
      <div className="mt-5 flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="address"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Your Home address
          </label>
          <input
            type="text"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="Your home address"
            style={{ transition: "all .15s ease" }}
            name="address"
            {...register("address", { required: true })}
            defaultValue={user?.identity?.homeAddress?.addressLine1}
          />
          {errors?.address ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.address?.message}
            </p>
          ) : null}
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="city"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            City/Area
          </label>
          <input
            type="text"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="City/Area"
            style={{ transition: "all .15s ease" }}
            name="city"
            {...register("city", { required: true })}
            defaultValue={user?.identity?.homeAddress?.city}
          />
          {errors?.city ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.city?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <StateSelect
            label="State of residence"
            {...register("state")}
            defaultValue={user?.identity?.homeAddress?.state}
          />
          {errors?.state ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.state?.message}
            </p>
          ) : null}
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <LocalSelect
            label="Local government area of residence"
            {...register("lga")}
            defaultValue={user?.identity?.homeAddress?.lga}
            lgas={selectedStateName ? selectedState.state.locals : []}
          />
          {errors?.lga ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.lga?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <StateSelect
            label="State of origin."
            {...register("OState")}
            defaultValue={user?.identity?.stateOfOrigin}
          />
          {errors?.OState ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.OState?.message}
            </p>
          ) : null}
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <LocalSelect
            label="Local government area of origin"
            {...register("OLga")}
            defaultValue={user?.identity?.lgaOfOrigin}
            lgas={selectedOStateName ? selectedOState.state.locals : []}
          />
          {errors?.OLga ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.OLga?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="nextName"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Next of kin name.
          </label>
          <input
            type="text"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="Next of kin name"
            style={{ transition: "all .15s ease" }}
            name="nextName"
            {...register("nextName", { required: true })}
            defaultValue={user?.nextOfKin?.name}
          />
          {errors?.nextName ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.nextName?.message}
            </p>
          ) : null}
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="nextPhone"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Next of kin Phone number.
          </label>
          <input
            type="tel"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="08123456789"
            style={{ transition: "all .15s ease" }}
            name="nextPhone"
            {...register("nextPhone", { required: true })}
            defaultValue={user?.nextOfKin?.phoneNumber}
          />
          {errors?.nextPhone ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.nextPhone?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="userCategory"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            User Category
          </label>
          <select
            className="form-select block w-full px-3 rounded border text-sm md:h-12 h-10 border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
            name="userCategory"
            {...register("userCategory", { required: true })}
            defaultValue={user?.identity?.userCategory}
            onChange={(e) =>
              setValue("userCategory", e.target.value, { shouldValidate: true })
            }
          >
            <option className="text-sm" value="">
              Not selected
            </option>
            <option className="text-sm" value="i-help-people-save">
              I Help People Save
            </option>
            <option className="text-sm" value="pos-agent">
              I am a POS Agent
            </option>
            <option className="text-sm" value="student">
              I am a Student
            </option>
            <option className="text-sm" value="influencer">
              I am an Influencer
            </option>
            <option className="text-sm" value="civil-servant">
              I am a Civil servant
            </option>
          </select>
          {errors?.userCategory ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.userCategory?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="text-right mt-5">
        <button
          type="submit"
          className={`bg-primary text-white text-base py-2 px-4 font-medium rounded ${
            otherInfoLoading ? "bg-opacity-70" : "bg-opacity-100"
          }`}
          disabled={otherInfoLoading}
        >
          {otherInfoLoading ? "..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default OtherInfo;

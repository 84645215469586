import {
  CogIcon,
  SwitchHorizontalIcon,
  UserGroupIcon,
  UsersIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";

const sidebarMenu = () => [
  {
    name: "Account",
    menuItems: [
      {
        name: "Dashboard",
        path: `/dashboard`,
        icon: <ViewGridIcon />,
      },
      {
        name: "Transactions",
        path: `/transactions`,
        icon: <SwitchHorizontalIcon />,
      },
      {
        name: "Savers",
        path: `/savers`,
        icon: <UsersIcon />,
      },
      {
        name: "Groups",
        path: `/groups`,
        icon: <UserGroupIcon />,
      },
      {
        name: "Settings",
        path: `/settings`,
        icon: <CogIcon />,
      },
    ],
  },
];

export default sidebarMenu;

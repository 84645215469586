import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Toaster } from "react-hot-toast";

import { AuthProvider } from "./context/AuthContext";

import Home from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsCondition from "pages/TermsCondition";
import Disclaimer from "pages/Disclaimer";
import FAQ from "pages/FAQ.jsx";

import Login from "./pages/Authentication/Login";
import Signup from "./pages/Authentication/Signup";
import EmailVerification from "./pages/Authentication/EmailVerification";
import VerifyEmail from "./pages/Authentication/VerifyEmail";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";

import Dashboard from "pages/Dashboard";
import Error404 from "./pages/Error404";
import PrivateRoute from "routes/PrivateRoute";
import AgentVerification from "pages/AgentVerification";
import Savers from "./pages/Savers/Savers";
import Transactions from "pages/Transactions";
import Settings from "pages/Settings";
import Sample from "pages/Sample";
import HomeScreen from "pages/HomeScreen";
import AgentCommissions from "pages/Commissions/AgentCommissions";
import GroupsSavings from "GroupSavings";
import GroupDetails from "GroupSavings/GroupDetails";
import PressPage from "./pages/Press";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Toaster position="top-center" toastOptions={{ duration: 10000 }} />
        <AuthProvider>
          <Routes>
            <Route exact path="/" element={<Home />} />

            <Route path="/sample" element={<Sample />} />
            <Route path="/home-screen" element={<HomeScreen />} />

            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/verify-email" element={<EmailVerification />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />

            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/verification" element={<AgentVerification />} />
              <Route path="/savers" element={<Savers />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/commissions" element={<AgentCommissions />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/groups" element={<GroupsSavings />} />
              <Route path="/groups/:id" element={<GroupDetails />} />
            </Route>

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/press" element={<PressPage />} />

            {/* Error 404 - Page Not Found */}
            <Route path="*" element={Error404} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "../../axios";
import toast from "react-hot-toast";

import { useUser } from "hooks/data/useUser";
import { useForm } from "react-hook-form";
import SpinnerIcon from "components/Icons/Spinner";
import { ArrowUpIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { useWalletBalance } from "hooks/data/useWalletBalance";

export default function Withdraw() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const formSchema = Yup.object().shape({
    accountDetails: Yup.string().required("Account details is required"),
    pin: Yup.number().required("Transaction pin is required"),
    amount: Yup.number()
      .required("Withdrawal amount is required")
      .min(2000, "Withdrawal amount must be at least NGN2000")
      .max(500000, "Withdrawal amount must be at greater than NGN500000"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const { user } = useUser();

  const { refetchWallet } = useWalletBalance();

  const [banks, setBanks] = useState([]);

  const BankSelect = React.forwardRef(
    ({ onChange, onBlur, name, label, defaultValue }, ref) => (
      <>
        <label
          htmlFor="accountNumber"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          {label}
        </label>
        <select
          className="border border-secondary_sky_base px-3 py-2 placeholder-grey_80 text-grey_40 bg-white focus:outline-none focus:ring w-full rounded-lg text-sm"
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
        >
          <option />
          {banks?.map((bank, idx) => (
            <option
              key={idx}
              className="flex items-center text-sm"
              value={JSON.stringify(bank)}
            >
              {`${bank.accountName} - ${bank.accountNumber} (${bank.bankName})`}
            </option>
          ))}
        </select>
      </>
    )
  );
  BankSelect.displayName = "BankSelect";

  useEffect(() => {
    if (user) {
      setBanks(user.bankAccounts);
    }
  }, [user]);

  const onSubmit = async (data) => {
    const { amount, pin, accountDetails } = data;
    const { accountNumber, bankCode } = JSON.parse(accountDetails);
    try {
      setLoading(true);
      const payload = {
        amount: parseInt(amount),
        txn_pin: pin,
        account_number: accountNumber,
        bank_code: bankCode,
      };
      await axios.post("/transactions/withdraw", payload);
      toast.success("Withdrawal successful 🥳");
      setLoading(false);
      refetchWallet();
      toggleModal();
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <button
        className="font-medium py-3 px-4 rounded-lg text-white flex items-center border border-white bg-transparent hover:text-primary hover:bg-white hover:border-transparent"
        type="button"
        onClick={toggleModal}
      >
        <ArrowUpIcon className="w-6 h-6 hidden md:flex" />
        <span className="md:ml-2">Withdraw</span>
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid rounded-t">
                  <h3 className="text-base font-medium text-black leading-5">
                    Withdraw to bank
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={toggleModal}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="relative p-6 flex-auto">
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="amount"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Amount
                      </label>
                      <input
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Amount"
                        style={{ transition: "all .15s ease" }}
                        {...register("amount", { required: true })}
                        autoComplete="off"
                      />
                      {errors?.amount ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.amount?.type === "typeError"
                            ? "Please enter a valid amount"
                            : errors.amount?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <BankSelect
                        label="Bank Name"
                        {...register("accountDetails", { required: true })}
                      />
                      {errors?.accountDetails ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.accountDetails?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="pin"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Your PIN
                      </label>
                      <input
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Enter your PIN"
                        style={{ transition: "all .15s ease" }}
                        {...register("pin", { required: true })}
                        autoComplete="off"
                      />
                      {errors?.pin ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.pin?.type === "typeError"
                            ? "Please enter a valid pin"
                            : errors.pin?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="flex items-center mt-2">
                      <ExclamationCircleIcon className="w-4 h-4 text-gray-600" />
                      <span className="text-xs text-gray-600 ml-1">
                        ₦100 will be charged as processing fee.
                      </span>
                    </div>
                  </div>
                  <div className="flex p-6 border-t border-solid rounded-b justify-center">
                    <button
                      className={
                        "bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal flex justify-center items-center text-center " +
                        (loading && "bg-primary/80 cursor-not-allowed")
                      }
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <SpinnerIcon /> : <span>Withdraw</span>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

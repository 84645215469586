import React from "react";
import { Link } from "react-router-dom";

import MainNavigation from "components/Navbars/MainNavigation";

// import hero from "assets/images/hero.png";
import woman from "assets/images/woman.png";
import circle from "assets/icons/circle.svg";
// import rect from "assets/images/rect.svg";
import MainFooter from "components/Footer/MainFooter";
import AppStore from "../assets/images/coming-soon-app-store.png";
import PlayStore from "../assets/images/playstore.png";

export default function Home() {
  return (
    <div>
      <MainNavigation />
      <section id="hero" className="my-4 py-20 px-4 md:px-20">
        <div className="container max-w-[1280px] mx-auto">
          <div className="flex flex-col-reverse justify-between md:flex-row">
            <div className="text-content w-full md:w-[48%] md:py-4 text-left md:text-left">
              <h1 className="text-5xl mb-7 font-head font-bold">
                Flexible Way to Save Digitally
              </h1>
              <p className="text-lg">
                Adashi is providing a credible and easy way for people to save
                money through a reliable network of agents or thrift collectors.
              </p>
              <div className="cta mt-7">
                {/* <div className="mb-5">
                  <Link
                    to="/auth/signup"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent font-medium rounded text-white bg-primary hover:bg-transparent hover:text-primary hover:border-primary"
                  >
                    Get started
                  </Link>
                </div> */}

                <div className=" lg:mt-0 lg:flex-shrink-0 space-x-4">
                  <a href="/#" className="inline-flex w-32">
                    <img src={AppStore} alt="..." />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=ng.adashi.twa"
                    className="inline-flex w-32"
                  >
                    <img src={PlayStore} alt="..." />
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-image relative mb-5 md:mb-0 md:w-[45%] hidden md:block">
              <img
                src={woman}
                alt="..."
                className="mx-auto relative z-10 left-0 right-0 h-full"
              />
              <img
                src={circle}
                alt="..."
                className="absolute bottom-0 left-0 right-0 z-0 w-3/4 mx-auto"
              />

              {/* <img
                src={rect}
                alt="..."
                className="absolute bottom-0 left-0 right-0 z-0 w-4/5 mx-auto"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
    </div>
  );
}

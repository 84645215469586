import mixpanel from "mixpanel-browser";
const isDebug = process.env.REACT_APP_ADASHI_ENV !== "production";
console.debug(`Is Debug: ${isDebug}`);

const prod_token = process.env.REACT_APP_MIXPANEL_TOKEN;

const mixpanel_token =
  process.env.REACT_APP_ADASHI_ENV !== "production" ? prod_token : prod_token;
mixpanel.init(mixpanel_token, { debug: isDebug });

export const SIGN_UP_VALIDATION_FAILED = "SIGN_UP_VALIDATION_FAILED";
export const SIGN_UP_COMPLETE = "SIGN_UP_COMPLETE";

export default mixpanel;

import React, { Fragment, useState } from "react";
import { useDebounce } from "libs/Hooks";
import axios from "../../axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import toast from "react-hot-toast";

import successful from "assets/icons/checked.svg";
import correct from "assets/icons/correct.svg";
import payout from "assets/icons/payout.svg";
import { useWalletBalance } from "hooks/data/useWalletBalance";

export default function Payout({ setReload }) {
  const [showModal, setShowModal] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [disableResend, setDisableResend] = useState(false);

  const formSchema = Yup.object().shape({
    account_id: Yup.string().required("Account details is required").trim(""),
    otp: Yup.string()
      .required("Otp is required")
      .test(
        "len",
        "OTP Must be exactly 5 characters",
        (val) => val.length === 5
      ),
    pin: Yup.string()
      .required("Transaction pin is required")
      .test(
        "len",
        "Pin Must be exactly 4 characters",
        (val) => val.toString().length === 4
      ),
    amount: Yup.number()
      .required("Payout amount is required")
      .min(500, "Payout amount must be at least NGN500"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, watch, reset, formState } =
    useForm(formOptions);
  const { errors } = formState;

  const [currentSaver, setCurrentSaver] = useState(null);

  const { refetchWallet } = useWalletBalance();

  useDebounce(() => verifyAccountId(), 1000, [watch("account_id")]);

  const verifyAccountId = async () => {
    const accountIdEl = document.getElementById("accountId");
    if (watch("account_id").length > 8) {
      try {
        setLoading(true);
        const response = await axios.get(`/saver/saver/${watch("account_id")}`);

        accountIdEl.classList.remove("border-red-500");
        accountIdEl.classList.add("border-green-500");
        setCurrentSaver(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        accountIdEl.classList.remove("border-green-500");
        accountIdEl.classList.add("border-red-500");
        setCurrentSaver(null);
        setLoading(false);
      }
    }
  };

  const onSubmit = async (data) => {
    const { account_id, pin, otp, amount } = data;

    const amountEl = document.getElementById("amount");

    try {
      setLoading(true);
      const payload = {
        accountId: account_id,
        pin,
        otp,
        amount: parseInt(amount, 10),
      };
      await axios.post("/agent/payout", payload);
      reset({ account_id: "", amount: "", pin: "", otp: "" });
      setSuccess(true);
      toast.success("Successfully paid out");
      refetchWallet();
      setLoading(false);
      setCurrentSaver(null);
    } catch (error) {
      toast.error(error.response.data.message);
      amountEl.classList.add("border-red-500");
      setLoading(false);
    }
  };

  const handleRequestOtp = async () => {
    try {
      setSendingOtp(true);
      await axios.get(`/saver/send-otp/${currentSaver?.saverId}`);
      setSendingOtp(false);
      setDisableResend(true);
      toast.success("Otp sent successfully");
    } catch (error) {
      setSendingOtp(false);
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.message);
      }
    }
  };

  setTimeout(() => setDisableResend(false), 60000);

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <div
          style={{
            backgroundImage:
              "linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%)",
          }}
          className="rounded-full w-12 md:w-16 h-12 md:h-16 flex items-center justify-center bg-payout"
        >
          <img src={payout} alt="..." className="w-5 md:w-7" />
        </div>
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid border-borderbg rounded-t">
                  <h3 className="text-base font-medium text-blackcolor leading-5">
                    Payout to saver
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form id="addressForm" onSubmit={handleSubmit(onSubmit)}>
                  {success ? (
                    <Fragment>
                      <div className="py-5">
                        <div className="w-full flex items-center justify-center">
                          <img src={successful} alt="..." />
                        </div>
                        <p className="text-sm text-center mt-4">
                          Congratulations!. 🎉 Payout successfully completed
                        </p>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="relative p-6 flex-auto">
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="amount"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Amount
                        </label>
                        <input
                          type="number"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          id="amount"
                          autoComplete="off"
                          placeholder="Amount"
                          style={{ transition: "all .15s ease" }}
                          {...register("amount", { required: true })}
                        />
                      </div>
                      <div className="relative w-full mb-5">
                        <label
                          htmlFor="accountId"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Account ID
                        </label>
                        <input
                          type="text"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          id="accountId"
                          placeholder="Account ID"
                          style={{ transition: "all .15s ease" }}
                          {...register("account_id", { required: true })}
                          autoComplete="off"
                        />
                        {currentSaver && (
                          <div className="flex justify-between mt-2">
                            <small className="text-xs text-green-600">
                              {`${currentSaver.firstName} ${currentSaver.lastName}`}
                            </small>
                            <img src={correct} alt="..." />
                          </div>
                        )}
                      </div>
                      <div className="relative w-full mb-5">
                        <label
                          htmlFor="otp"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          OTP
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <input
                              type="text"
                              id="otp"
                              className={
                                "focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md pl-3 sm:text-sm border-gray-300 " +
                                (!currentSaver && "bg-gray-100")
                              }
                              style={{ transition: "all .15s ease" }}
                              {...register("otp", { required: true })}
                              autoComplete="off"
                              disabled={!currentSaver}
                            />
                          </div>
                          {disableResend ? (
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary opacity-70 cursor-not-allowed"
                              disabled
                            >
                              ---
                            </button>
                          ) : (
                            <button
                              type="button"
                              className={
                                "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary " +
                                ((!currentSaver ||
                                  sendingOtp ||
                                  disableResend) &&
                                  "opacity-70 cursor-not-allowed")
                              }
                              disabled={
                                !currentSaver || sendingOtp || disableResend
                              }
                              onClick={handleRequestOtp}
                            >
                              {sendingOtp ? (
                                <span>Sending ...</span>
                              ) : (
                                <span>Get Code</span>
                              )}
                            </button>
                          )}
                        </div>
                        {errors?.otp ? (
                          <p className="text-left text-red-600 text-xs mt-1">
                            {errors.otp?.type === "typeError"
                              ? "Please enter a valid otp"
                              : errors.otp?.message}
                          </p>
                        ) : null}
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="pin"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Your PIN
                        </label>
                        <input
                          type="number"
                          className={`border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 ${
                            !currentSaver ? "bg-gray-100" : "bg-white"
                          } rounded text-sm shadow focus:outline-none focus:ring w-full`}
                          placeholder="Enter your PIN"
                          style={{ transition: "all .15s ease" }}
                          autoComplete="off"
                          {...register("pin", { required: true })}
                          disabled={!currentSaver}
                        />
                        {errors?.pin ? (
                          <p className="text-left text-red-600 text-xs mt-1">
                            {errors.pin?.type === "typeError"
                              ? "Please enter a valid pin"
                              : errors.pin?.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {/*footer*/}
                  <div className="flex p-6 border-t border-solid rounded-b justify-center">
                    {!success && (
                      <button
                        className={`bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none flex justify-center items-center mb-1 ease-linear transition-all duration-150 font-normal ${
                          !currentSaver ? "bg-opacity-70" : "bg-opacity-100"
                        }`}
                        disabled={!currentSaver || loading}
                        type="submit"
                      >
                        {loading ? "..." : "Payout"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "../../../axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const BasicInfo = ({ user, refetch }) => {
  const formSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required").trim(""),
    lastName: Yup.string().required("Last name is required").trim(""),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .min(11, "Phone number must be at least 11 digits")
      .max(11, "Phone number must be at greater than 11 digits"),
    birthday: Yup.string().required("Date of birth is required"),
    gender: Yup.string().required("Gender is required"),
    bvn: Yup.string()
      .required("BVN is required")
      .min(10, "BVN must be at least 10 digits")
      .max(11, "BVN must be at greater than 11 digits"),
  });
  const [basicInfoLoading, setBasicInfoLoading] = useState(false);

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const basicInfoSubmit = async (values) => {
    try {
      const { firstName, lastName, birthday, gender, bvn, phoneNumber } =
        values;
      setBasicInfoLoading(true);
      const response = await axios.patch("/agent/basic-info", {
        firstName,
        lastName,
        phoneNumber,
        birthday,
        gender,
        bvn,
      });
      console.log(response.data);
      toast.success(response.data.message);
      setBasicInfoLoading(false);
      refetch();
    } catch (error) {
      toast.error(error.response.data);
      setBasicInfoLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(basicInfoSubmit)}>
      <div className="flex flex-col md:flex-row justify-between mt-5 md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="firstName"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            First name
          </label>
          <input
            type="text"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="First name"
            style={{ transition: "all .15s ease" }}
            autoComplete="off"
            {...register("firstName", { required: true })}
            defaultValue={user?.firstName}
          />
          {errors?.firstName ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.firstName?.message}
            </p>
          ) : null}
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="lastName"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Last name
          </label>
          <input
            type="text"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="Last name"
            style={{ transition: "all .15s ease" }}
            autoComplete="off"
            {...register("lastName", { required: true })}
            defaultValue={user?.lastName}
          />
          {errors?.lastName ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.lastName?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="email"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Email address.
          </label>
          <input
            id="email"
            type="email"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="Email address"
            style={{ transition: "all .15s ease" }}
            {...register("email", {
              disabled: true,
              required: true,
            })}
            defaultValue={user?.email}
          />
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="phoneNumber"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Phone number.
          </label>
          <input
            type="tel"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="08123456789"
            style={{ transition: "all .15s ease" }}
            autoComplete="off"
            {...register("phoneNumber", {
              required: true,
              minLength: 11,
              maxLength: 11,
            })}
            defaultValue={user?.phoneNumber}
          />
          {errors?.phoneNumber ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.phoneNumber?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="birthday"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Birthday.
          </label>
          <input
            type="date"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="Birthday"
            style={{ transition: "all .15s ease" }}
            {...register("birthday", { required: true })}
            defaultValue={user?.identity?.birthday}
          />
          {errors?.birthday ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.birthday?.message}
            </p>
          ) : null}
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="gender"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Gender.
          </label>
          <select
            className="form-select block w-full px-3 rounded border text-sm md:h-12 h-10 border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
            name="gender"
            {...register("gender", { required: true })}
            defaultValue={user?.identity?.gender}
          >
            <option className="text-sm">Not selected</option>
            <option className="text-sm" value="Male">
              Male
            </option>
            <option className="text-sm" value="Female">
              Female
            </option>
          </select>
          {errors?.gender ? (
            <p className="text-left text-red-600 text-xs mt-1">
              {errors.gender?.message}
            </p>
          ) : null}
        </div>
      </div>
      <div className="relative w-full mb-5">
        <label
          htmlFor="bvn"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          BVN
        </label>
        <input
          type="string"
          className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
          placeholder="BVN"
          style={{ transition: "all .15s ease" }}
          autoComplete="off"
          {...register("bvn", { required: true })}
          defaultValue={user?.identity?.bvn}
        />
        {errors?.bvn ? (
          <p className="text-left text-red-600 text-xs mt-1">
            {errors.bvn?.message}
          </p>
        ) : null}
      </div>
      <div className="text-right mt-5">
        <input
          className={`bg-primary text-white text-base py-2 px-4 font-medium rounded ${
            basicInfoLoading ? "bg-opacity-70" : "bg-opacity-100"
          }`}
          disabled={basicInfoLoading}
          value={basicInfoLoading ? "..." : "Submit"}
          type="submit"
        />
      </div>
    </form>
  );
};

export default BasicInfo;

import RequiredError from "components/RequiredError";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { changePassword } from "../../apicore";
import toast from "react-hot-toast";
import ChangeTrxnPin from "components/Modals/ChangeTrxnPin";

export default function Security() {
  const [showModal, setModalVisibility] = useState(false);

  const toggleModal = () => setModalVisibility(!showModal);

  const formSchema = Yup.object().shape({
    current_password: Yup.string().required("Current password is required"),
    new_password: Yup.string()
      .required("New password is required")
      .min(6, "Password must be at 6 characters long"),
    confirm_new_password: Yup.string()
      .required("New password is required")
      .oneOf([Yup.ref("new_password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const mutation = useMutation(changePassword, {
    onSuccess: (res) => {
      toast.success(res.message);
      reset({
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      });
    },
    onError: (error) => {
      console.log(error);
      toast.error(error.response.data.message);
    },
  });

  const { mutate, isLoading } = mutation;

  const onSubmit = (data) => mutate(data);

  return (
    <React.Fragment>
      <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full block">
        <div className="bg-white px-4 py-4 border rounded mb-8">
          <h3 className="font-semibold mb-5 text-lg">Security</h3>
          <div className="flex justify-between flex-col-reverse md:flex-row">
            <div className="left w-full md:w-2/3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-4 xl:-mx-4">
                  <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                    <div className="mb-6">
                      <label
                        htmlFor="currentPassword"
                        className="text-sm font-medium text-gray-900 block mb-2"
                      >
                        Current Password
                      </label>
                      <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                        {...register("current_password", { required: true })}
                      />
                      {errors.current_password?.type === "required" && (
                        <RequiredError>
                          Current password is required
                        </RequiredError>
                      )}
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                    <div className="mb-6"></div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                    <div className="mb-6">
                      <label
                        htmlFor="newPassword"
                        className="text-sm font-medium text-gray-900 block mb-2"
                      >
                        New password
                      </label>
                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                        {...register("new_password", { required: true })}
                      />
                      {errors.new_password?.type === "required" && (
                        <RequiredError>New password is required</RequiredError>
                      )}
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                    <div className="mb-6">
                      <label
                        htmlFor="confirmPassword"
                        className="text-sm font-medium text-gray-900 block mb-2"
                      >
                        Confirm new password
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                        {...register("confirm_new_password", {
                          required: true,
                        })}
                      />
                      {errors.confirm_new_password?.type === "required" && (
                        <RequiredError>
                          Confirm password is required
                        </RequiredError>
                      )}
                      {errors.confirm_new_password?.type === "oneOf" && (
                        <RequiredError>
                          {errors.confirm_new_password?.message}
                        </RequiredError>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-2 text-left">
                  <button
                    type="submit"
                    className={
                      "py-3 px-4 bg-primary text-white rounded font-medium" +
                      (isLoading && "bg-opacity-50 cursor-not-allowed")
                    }
                    disabled={isLoading}
                  >
                    {isLoading ? "..." : "Save Changes"}
                  </button>
                </div>
              </form>

              <div className="mt-6">
                <button
                  className="px-4 py-3 rounded shadow border border-primary"
                  onClick={toggleModal}
                >
                  Change Pin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangeTrxnPin show={showModal} toggle={toggleModal} />
    </React.Fragment>
  );
}

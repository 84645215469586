import React from "react";
import MainNavigation from "components/Navbars/MainNavigation";
import MainFooter from "components/Footer/MainFooter";

export default function PrivacyPolicy() {
  return (
    <div>
      <MainNavigation />

      <section className="my-4 py-3 md:py-4 px-4 md:px-20 md:mt-20">
        <div className="container mx-auto max-w-[1280px]">
          <h2 className="text-2xl md:text-3xl text-blackText font-semibold mb-10">
            Privacy Policy
          </h2>
          <p className="mb-5 opacity-80">
            While using Adashi website, mobile applications or its custom
            extensions or availing the products and services via the online
            application forms and questionnaires, Adashi and its Affiliates may
            become privy to the personal information of its customers, including
            information that is of a confidential nature. Adashi is strongly
            committed to protect the privacy of its customers and has taken all
            necessary and reasonable measures to protect the confidentiality of
            the customer information and its transmission through the WWW or VPN
            and it shall not be held liable for disclosure of the confidential
            information when in accordance with this privacy Commitment or in
            terms of the agreements, if any, with the customers.
          </p>
          <p className="mb-5 opacity-80">
            Adashi endeavors to safeguard and ensure the security of the
            information provided by the Customer. Adashi uses SSL encryption,
            for the transmission of the information, which is currently the
            permitted level of encryption in Nigeria. When the information
            provided by the Customers is not transmitted through this
            encryption, the Customers' system (if configured accordingly) will
            display an appropriate message ensuring the best level of secrecy
            for the Customer's information.
          </p>
          <p className="mb-5 opacity-80">
            The Customer would be required to cooperate with Adashi to ensure
            the security of the information, and it is recommended that the
            Customers necessarily choose their passwords carefully such that no
            unauthorized access is made by a third party.
          </p>
          <p className="mb-5 opacity-80">
            To make the password complex and difficult for others to guess, the
            Customers should use a combination of alphabets, numbers and special
            characters (like ! @, #, $ etc.). The Customers should undertake not
            to disclose their password to anyone or keep any written or other
            records of the password such that a third party could access it.
            Adashi undertakes not to disclose the information provided by the
            Customers to any person unless such action is necessary to:
          </p>
          <ul className="list-disc list-inside mb-8">
            <li className="mb-4 opacity-80">
              Conform to legal requirements or comply with legal process;
            </li>
            <li className="mb-4 opacity-80">
              Protect and defend Adashi's or its Affiliates' rights, interests
              or property;
            </li>
            <li className="mb-4 opacity-80">
              Enforce the terms and conditions of the products or services; or
            </li>
            <li className="mb-4 opacity-80">
              Act to protect the interests of Adashi, its Affiliates, or its
              members, constituents or of other persons.
            </li>
          </ul>
          <p className="mb-5 opacity-80">
            The Customers shall not disclose to any other person, in any manner
            whatsoever, any information relating to Adashi or its Affiliates of
            a confidential nature obtained while availing the services through
            the website. Failure to comply with this obligation shall be deemed
            a serious breach of the terms herein and shall entitle Adashi or its
            Affiliates to terminate the services, without prejudice to any
            damages, to which the customer may be entitled otherwise.
          </p>
          <p className="mb-5 opacity-80">
            Adashi will limit the collection and use of customer information
            only on a need-to-know basis to deliver better service to the
            customers. Adashi may use and share the information provided by the
            Customers with its Affiliates and third parties for providing
            services and any service-related activities such as collecting
            subscription fees for such services, and notifying or contacting the
            Customers regarding any problem with, or the expiration of, such
            services. In this regard, it may be necessary to disclose the
            customer information to one or more agents and contractors of Adashi
            and their subcontractors, but such agents, contractors, and
            sub-contractors will be required to agree to use the information
            obtained from Adashi only for these purposes.
          </p>
          <p className="mb-5 opacity-80">
            <strong>Know Your Customer (KYC) Policy:</strong>
            <ol className="space-y-2">
              <li>
                The Company may require Users to upload certain information and
                documents that may be necessary to ascertain their eligibility
                to use certain features of the Services including but not
                limited to their identification documents (“KYC Documents”)
              </li>
              <li>
                The User hereby authorizes the Company and any third-party
                service provider it may engage with or interact with in
                connection with using the Platform to process KYC Documents and
                ascertain the User’s eligibility. Any processing undertaken by
                the Company shall be in accordance with its Privacy Policy and
                these Terms. It is hereby clarified that as far as the
                permission of the KYC Documents by a third-party service
                provider is concerned, the same shall be governed by the privacy
                policy of such third party service provider.
              </li>
              <li>
                The User agrees that it may be required to submit additional
                documents as and when required by the Company or any of its
                third party service providers and if, in such an event, any
                additional information, data, or documentation is required
                (collectively, “Top-Up Documents”), the User hereby agrees to
                share such Top-Up Documents promptly upon request, and further,
                authorizes the Company to process such Top-Up Documents.
              </li>
              <li>
                The User agrees and warrants to provide valid, true, complete,
                and up-to- date KYC Documents and Top-Up Documents. The User
                further acknowledges that any incorrect or misleading
                information provided shall constitute a material breach of these
                Terms, and the User’s access to certain features of the Services
                may be limited or denied in such event
              </li>
              <li>
                The list of the KYC Documents and Top-Up Documents may be
                provided to the User at the time of creating the
                Profile/signing-up or at a later stage
              </li>
            </ol>
          </p>
          <p className="mb-5 opacity-80">
            <strong>Cookie Policy:</strong> Adashi website uses Google
            Analytics, a web analytics service provided by Google, Inc.
            ("Google"). Google Analytics uses cookies that are text files
            containing small amounts of information{" "}
            {"this does not include personal sensitive information"} which are
            downloaded to your device when you visit a website to provide a
            personalized browsing experience. Cookies do lots of different jobs,
            like allowing users to navigate between pages efficiently,
            remembering their preferences, and generally improving their
            browsing experience. These cookies collect information analytics
            about how users use a website, for instance often visited pages. All
            information collected by third party cookies is aggregated and
            therefore anonymous. By using our website user/s agree that these
            types of cookies can be placed on his/her device. User/s is free to
            disable/delete these cookies by changing his/her web browser
            settings. Adashi is not responsible for cookies placed in the device
            of user/s by any other website and information collected thereto.
            The Customer authorizes Adashi to exchange, share, part with all
            information related to the details and transaction history of the
            Customers to its Affiliates / banks / financial institutions /
            credit bureaus / agencies/participation in any telecommunication or
            electronic clearing network as may be required by law, customary
            practice, credit reporting, statistical analysis and credit scoring,
            verification or risk management and shall not hold Adashi liable for
            use or disclosure of this information.
          </p>
          <p className="mb-2 opacity-80">
            Last Edited on
            <span className="opacity-100 text-blackText ml-2">
              04 August, 2023
            </span>
          </p>
        </div>
      </section>

      <MainFooter />
    </div>
  );
}

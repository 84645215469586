import { NavLink, useLocation } from "react-router-dom";

const Item = ({ data, isLoading }) => {
  const location = useLocation();
  return isLoading ? (
    <div className="h-6 mb-3 bg-gray-600 rounded animate-pulse" />
  ) : (
    <li
      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
        location.pathname === data.path && "bg-light"
      }`}
    >
      <NavLink
        end
        to={data.path}
        className={`block text-dark hover:text-primary truncate transition duration-150 ${
          location.pathname === data.path && "text-primary"
        }`}
      >
        <div className="flex items-center">
          <div className="w-6 h-6 shrink-0">{data.icon}</div>
          <span className="text-sm md:text-base font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
            {data.name}
          </span>
        </div>
      </NavLink>
    </li>
  );
};

Item.defaultProps = {
  data: null,
  isLoading: false,
};

export default Item;

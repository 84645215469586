import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import OtpForm from "react-otp-ui";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { fetchUserDetails, setTransactionPin } from "../../apicore";

const SetTrxnPin = ({ show, toggleModal }) => {
  const [otp, setOtp] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOtpChange = (event) => {
    if (event.otpValue.length === 4) {
      setOtp(event.otpValue);
    }
  };
  const handleConfirmPinChange = (event) => {
    if (event.otpValue.length === 4) {
      setConfirmPin(event.otpValue);
    }
  };

  const { refetch: refetchUser } = useQuery("user-details", fetchUserDetails);

  useEffect(() => {
    const inputEl = document.querySelectorAll(".setting-pin-input");

    if (otp.length === 4 && confirmPin.length === 4) {
      if (otp !== confirmPin) {
        toast.error("Pin and confirm pin mismatch", { duration: 700 });
        for (let i = 0; i < inputEl.length; i++) {
          inputEl[i].classList.add("!border-red-500");
        }
      } else {
        setDisableConfirm(false);
      }
    }

    return () => {
      for (let i = 0; i < inputEl.length; i++) {
        inputEl[i].classList.remove("!border-red-500");
      }
    };
  }, [otp, confirmPin]);

  useEffect(() => {
    const inputEl = document.querySelectorAll(".setting-pin-input");

    for (let i = 0; i < inputEl.length; i++) {
      inputEl[i].setAttribute("autocomplete", "off");
    }
  }, []);

  const mutation = useMutation(setTransactionPin, {
    onSuccess: (res) => {
      console.log(res);
      setLoading(false);
      toast.success("Pin set successful");
      refetchUser();
      toggleModal();
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const { mutate } = mutation;

  const setPinHandler = (event) => {
    event.preventDefault();
    mutate(otp);
  };

  return (
    <Transition appear as={Fragment} show={show}>
      <Dialog
        className="fixed inset-0 z-50 overflow-y-auto text-gray-800"
        onClose={() => {}}
      >
        <div className="flex items-center justify-center h-screen p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>
          <span aria-hidden="true" className="inline-block align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block p-10 my-10 space-y-5 overflow-hidden text-left align-middle transition-all transform bg-white rounded shadow-xl">
              <Dialog.Title
                as="h2"
                className="text-lg font-medium leading-5 text-center"
              >
                Set Transaction Pin
              </Dialog.Title>
              <form onSubmit={setPinHandler}>
                <div className="mt-6">
                  <div className="relative mb-4">
                    <span className="text-sm font-medium text-gray-900 block mb-2 text-left">
                      Enter Pin
                    </span>
                    <div className="flex justify-center mt-5 mb-2">
                      <OtpForm
                        onChange={handleOtpChange}
                        className="setting-pin-input"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <span className="text-sm font-medium text-gray-900 block mb-2 text-left">
                      Confirm Pin
                    </span>
                    <div className="flex justify-center mt-5 mb-2">
                      <OtpForm
                        onChange={handleConfirmPinChange}
                        className="setting-pin-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-center">
                  <button
                    className={
                      "bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                      ((disableConfirm || loading) && "bg-opacity-30")
                    }
                    disabled={setDisableConfirm}
                  >
                    {loading ? "..." : "Confirm"}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SetTrxnPin;

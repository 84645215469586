import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthContext from "context/AuthContext";
import axios from "../../axios";

import toast from "react-hot-toast";
import useTitle from "hooks/useTitle";

export default function ForgotPassword() {
  useTitle("Adashi - Forgot Password");

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: "" } });
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  const handleForgot = async (values) => {
    try {
      setLoading(true);
      const { email } = values;
      const response = await axios.post("/auth/forgot-password", { email });

      if (response.status === 200) {
        setLoading(false);
        toast.success(`Password reset link has been sent to ${email}`);
        reset({ email: "" });
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (err) {
      console.error(err);
      if (err.response) {
        toast.error(err.response.data.message || err.response.data);
      }
      setLoading(false);
    }
  };

  return (
    <div className="bg-light min-h-screen grid place-content-center">
      <div className="text-center w-screen md:w-auto">
        <h1 className="text-3xl text-primary mb-7">Adashi.ng</h1>

        <h2 className="text-gray-900 mb-4 text-3xl md:text-4xl font-semibold leading-9">
          Forgot Password
        </h2>
        <p className="text-sm text-dark">
          Enter your email address to get a password reset link
        </p>

        <form
          onSubmit={handleSubmit(handleForgot)}
          className="login-box max-w-xl md:w-[34rem] mx-auto mt-5 px-4 md:px-0"
        >
          <div className="mb-6">
            <label
              htmlFor="email"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Email address
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
              placeholder="example@email.com"
              {...register("email", { required: true })}
            />
            {errors.email?.type === "required" && (
              <p className="text-left text-red-600 text-xs mt-1">
                Email address is required
              </p>
            )}
          </div>

          <button
            type="submit"
            className="py-1 border-primary border w-full text-center bg-primary text-white text-sm h-12"
            disabled={loading}
          >
            {loading ? "..." : "Send password link"}
          </button>
          <div className="text-center mt-4">
            <p className="text-dark text-sm">
              Still need help?{" "}
              <a href="mailto:support@adashi.ng" className="text-primary">
                Contact Support
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

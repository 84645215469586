import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import React from "react";

const RecentTransactions = ({ transactions }) => {
  const trxnPurposeEnum = {
    GroupSavings: "Savings",
    GroupPayout: "Payout",
  };
  return (
    <React.Fragment>
      <div className="flex justify-between items-center mb-5">
        <h3 className="font-semibold text-lg text-blackText">
          Recent Transactions
        </h3>
      </div>
      <div className="w-full xl:mb-0">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    <span className="invisible">Id</span>
                  </th>
                  <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    {/* Saver ID */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.length === 0 ? (
                  <tr className="h-12 min-w-full flex items-center">
                    <td className="text-center w-full min-w-full absolute h-14 flex items-center justify-center bg-light p-0">
                      <span className="">No Transaction</span>
                    </td>
                  </tr>
                ) : (
                  transactions.map((trxn, idx) => (
                    <tr key={idx} className="hover:bg-light cursor-pointer">
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap py-3 p-4 text-sm">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 mr-2">
                            {trxn.txn_type === "Credit" ? (
                              <ArrowDownIcon className="w-6 h-6 text-dark" />
                            ) : (
                              <ArrowUpIcon className="w-6 h-6 text-dark" />
                            )}
                          </div>
                          <div className="">
                            <span className="font-medium block mb-1">
                              {trxn?.saver?.saverId}
                            </span>
                            <span className="text-sm">₦ {trxn?.amount}</span>
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap py-3 p-4 text-sm">
                        <div className="font-medium mb-1">
                          {trxnPurposeEnum[trxn?.purpose]}
                        </div>
                        <div className="text-sm">
                          {new Date(trxn.createdAt).toDateString()}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecentTransactions;

import React, { useState } from "react";
import axios from "../../axios";

import email from "../../assets/icons/mail.svg";
import SpinnerIcon from "../../components/Icons/Spinner";
import { useMutation } from "react-query";
import { useUser } from "../../hooks/data/useUser";
import toast from "react-hot-toast";

export default function EmailVerification() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const { user } = useUser();

  const { mutate: sendEmail, isLoading } = useMutation(
    (email) => axios.post("/auth/activation/resend", { email }),
    {
      onSuccess: () => {
        // handle success logic, for example show a success message
        toast.success("Email sent successfully");
        setIsDisabled(true);
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        setTimeout(() => {
          clearInterval(intervalId);
          setIsDisabled(false);
          setTimeLeft(60);
        }, 60000);
      },
      onError: (error) => {
        // handle error logic, for example show an error message
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occured resending email");
        }
      },
    }
  );

  return (
    <div className="bg-light min-h-screen grid place-content-center">
      <div className="text-center w-11/12 md:w-auto mx-auto">
        <img
          src={email}
          alt="..."
          className="w-24 h-24 md:w-40 md:h-40 mx-auto"
        />
        <h1 className="mt-10 font-medium text-3xl md:text-4xl md:leading-9 mb-5 text-blackText">
          Verify your account
        </h1>
        <p className="w-full md:w-3/5 mx-auto text-sm text-dark">
          We have sent a confirmation mail to your email address. This
          verification link is valid for 24 hours only.
        </p>

        <div className="flex justify-center items-center mt-6 mb-4">
          <button
            type="submit"
            className={
              "py-1 border-primary border px-4 text-center bg-primary text-white text-sm h-12 flex justify-center items-center " +
              ((isLoading || isDisabled) && "bg-opacity-50")
            }
            disabled={isLoading || isDisabled}
            onClick={() => sendEmail(user.email)}
          >
            {isLoading ? <SpinnerIcon /> : "Resend Link"}
          </button>
        </div>
        {isDisabled && (
          <div className="text-center text-xs">
            You can resend in {timeLeft}
          </div>
        )}
      </div>
    </div>
  );
}

import { SortableContainer } from "react-sortable-hoc";

import React from "react";
import GroupMember from "../GroupMember";

const GroupMembers = ({ items, details }) => {
  return (
    <div className="w-full mb-12 xl:mb-0">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                  Saver ID
                </th>
                <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                  Name
                </th>
                <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                  Payments
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length === 0 ? (
                <tr className="h-12 min-w-full flex items-center">
                  <td className="text-center w-full min-w-full absolute h-14 flex items-center justify-center bg-light p-0">
                    <span className="">No Members</span>
                  </td>
                </tr>
              ) : (
                items.map((value, index) => {
                  const yetToCollect = details?.nextRecipients.find((r) => {
                    return r === value._id;
                  });

                  return (
                    <GroupMember
                      key={`item-${index}`}
                      index={index}
                      value={value}
                      disabled={details.status !== "PENDING"}
                      not_received={yetToCollect}
                    />
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SortableContainer(GroupMembers);

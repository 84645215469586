export const API_ENDPOINTS = {
  PRODUCTS: "/products",
  PRODUCTS_POPULAR: "/popular-products",
  PRODUCTS_FREE_DOWNLOAD: "/free-downloads/digital-file",
  CATEGORIES: "/categories",
  TAGS: "/tags",
  SHOPS: "/shops",
  TOP_SHOPS: "/top-shops",
  ORDERS: "/orders",
  ORDERS_CHECKOUT_VERIFY: "orders/checkout/verify",
  ORDERS_DOWNLOADS: "/downloads",
  GENERATE_DOWNLOADABLE_PRODUCT_LINK: "/downloads/digital-file",

  //AUTH
  AUTH: {
    BASE: "/auth",
    LOGIN: "/auth/login",
    SIGNUP: "/auth/signup",
    LOGOUT: "/auth/logout",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
    VERIFY_EMAIL: "/auth/verify-email",
    RESEND_EMAIL_VERIFICATION: "/auth/resend-email-verification",
  },

  //USERS
  USERS: "/users",
  USERS_ME: "/customers/me",
  SETTINGS: "/settings",
  SETTINGS_CONTACT_US: "/contact-us",
  UPLOADS: "/attachments",

  // SAVINGGROUPS
  SAVINGGROUPS: "/savings-group",
  SAVINGGROUPS_JOIN: "/savings-groups/join",
  SAVINGGROUPS_LEAVE: "/savings-group/leave",
  SAVINGGROUPS_CREATE: "/savings-group/create",
  SAVINGGROUPS_UPDATE: "/savings-group/update",
  SAVINGGROUPS_DELETE: "/savings-group/delete",

  SAVINGGROUPS_ARCHIVE: "/savings-group/archive",
};

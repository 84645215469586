import { createContext, useState, useEffect } from "react";
import axios from "../axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState({});

  axios.defaults.withCredentials = true;

  const loginUser = (user, token) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    setUser(user);
  };

  const logoutUser = async () => {
    try {
      setLoading(true);
      await axios.get("/auth/logout", {
        withCredentials: true,
        credentials: "include",
        method: "GET",
      });
      setUser(null);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshToken = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/auth/refresh/token", {
        withCredentials: true,
        credentials: "include",
        method: "GET",
      });

      setTimeout(() => {
        refreshToken();
      }, data.expires_in * 1000 - 500);

      setUser(data.user);
      axios.defaults.headers.common = { Authorization: `Bearer ${data.token}` };
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loading,
    auth,
    setAuth,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

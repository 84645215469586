import React from "react";
import { Link } from "react-router-dom";

import logo from "assets/logo/logo.svg";

export default function MainNavigation() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav
        className={
          "relative bg-white flex flex-wrap items-center justify-between py-4 px-4"
        }
      >
        <div className="container max-w-[1280px] md:w-11/12 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="text-gray-800 text-sm font-bold leading-relaxed mr-4 md:mr-0 py-2 whitespace-nowrap flex md:items-center"
              to="/"
            >
              <img src={logo} alt="adashi.ng" className="w-28" />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? (
                <svg
                  className="w-7 h-7"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="w-7 h-7"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link
                  className="px-3 py-4 lg:py-2 flex items-center text-base hover:font-semibold"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="flex items-center">
                <a
                  className="px-3 py-4 lg:py-2 flex items-center text-base hover:font-semibold"
                  href="https://blog.adashi.ng"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>

              <li className="flex items-center">
                <Link
                  className="px-3 py-4 lg:py-2 flex items-center text-base hover:font-semibold"
                  to="/faqs"
                >
                  FAQs
                </Link>
              </li>

              <li className="flex items-center">
                <Link
                  to="/auth/login"
                  className="font-medium md:px-4 py-2 hover:font-semibold focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  style={{ transition: "all .15s ease" }}
                >
                  Login
                </Link>
              </li>

              <li className="flex items-center">
                <Link
                  className="bg-primary text-white font-medium px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 md:ml-3 mb-3"
                  style={{ transition: "all .15s ease" }}
                  to="/auth/signup"
                >
                  Get started
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

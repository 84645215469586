import { PlusIcon } from "@heroicons/react/solid";
import AddSavingsGroupModal from "./components/Modals/AddGroup";
import { DashboardLayout } from "layouts";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import axios from "../axios";
import SavingsGroups from "./components/List/SavingsGroups";
import { Tab } from "@headlessui/react";
import cn from "classnames";

const GroupsSavings = () => {
  const [showAdd, setShowAdd] = useState(false);
  const toggleAdd = () => setShowAdd(!showAdd);

  const [savingGroups, setSavingGroups] = useState([]);

  function addGroup(group) {
    setSavingGroups([...savingGroups, group]);
  }

  useEffect(() => {
    const fetchSavingsGroups = async () => {
      try {
        const response = await axios.get("/savings-group/me");
        setSavingGroups(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSavingsGroups();
  }, []);

  const archivedGroups = useMemo(() => {
    if (!savingGroups.length > 0) return [];
    return savingGroups.filter((group) => group?.archived === true);
  }, [savingGroups]);

  const unarchivedGroups = useMemo(() => {
    if (!savingGroups.length > 0) return [];
    return savingGroups.filter(
      (group) => group?.archived === false || group.archived == undefined
    );
  }, [savingGroups]);

  return (
    <DashboardLayout>
      <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* <!-- Left: Title --> */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
              Savings Groups ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Filter button */}
            {/* <FilterButton /> */}
            {/* Add view button */}
            <button
              className="btn bg-primary hover:bg-primary/75 text-white"
              onClick={toggleAdd}
            >
              <PlusIcon className="text-white h-6 w-6" />
              <span className="hidden sm:block ml-2">Add Groups</span>
            </button>
          </div>
        </div>

        <Tab.Group>
          <Tab.List className="space-x-4 md:space-x-6">
            <Tab>
              {({ selected }) => (
                <span className={cn("text-lg", selected && "font-semibold")}>
                  All
                  {console.log(selected)}
                </span>
              )}
            </Tab>
            <Tab>
              {({ selected }) => (
                <span className={cn("text-lg", selected && "font-semibold")}>
                  Archived
                </span>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-6">
            <Tab.Panel>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border md:border-t-0 border-gray-300 rounded p-6">
                <SavingsGroups groups={unarchivedGroups} />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border md:border-t-0 border-gray-300 rounded p-6">
                <SavingsGroups groups={archivedGroups} />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      {showAdd && (
        <AddSavingsGroupModal
          show={showAdd}
          setShow={setShowAdd}
          addGroup={addGroup}
          toggleAdd={toggleAdd}
        />
      )}
    </DashboardLayout>
  );
};

export default GroupsSavings;

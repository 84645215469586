function getConstants() {
  if (process.env.REACT_APP_ADASHI_ENV === "production") {
    return {
      baseUrl: "https://api.adashi.ng/api/v1",
    };
  }
  if (process.env.REACT_APP_ADASHI_ENV === "staging") {
    return {
      baseUrl: "https://api.adashi.xyz/api/v1",
    };
  }

  return {
    baseUrl: "http://localhost:4000/api/v1",
  };
}

const constants = getConstants();
export default constants;

import React from "react";

import { UserGroupIcon } from "@heroicons/react/outline";
import Savings from "components/Modals/Savings";
import Payout from "components/Modals/Payout";
import { useNavigate } from "react-router-dom";

const QuickActions = ({ refetchBalance }) => {
  const navigate = useNavigate();
  return (
    <div>
      <h3 className="font-semibold mb-5 text-lg text-blackText">
        Quick Actions
      </h3>
      <ul className="mt-3 grid gap-5 sm:gap-8 grid-cols-3">
        <li className="col-span-1 flex flex-col sm:shadow-sm sm:rounded-lg sm:px-4 sm:py-6 sm:bg-savings">
          <div className="flex justify-center sm:justify-end">
            <Savings refetchBalance={refetchBalance} />
          </div>
          <div
            className="mt-4 text-sm sm:text-lg text-center sm:text-left font-semibold"
            style={{ color: "#007B55" }}
          >
            Daily Savings
          </div>
        </li>
        <li className="col-span-1 flex flex-col sm:shadow-sm sm:rounded-lg sm:px-4 sm:py-6 sm:bg-payout">
          <div className="flex justify-center sm:justify-end">
            <button className="">
              <Payout refetchBalance={refetchBalance} />
            </button>
          </div>
          <div className="mt-4 text-sm sm:text-lg text-center sm:text-left font-semibold text-[#04297a]">
            Daily Payout
          </div>
        </li>
        <li className="col-span-1 flex flex-col sm:shadow-sm sm:rounded-lg sm:px-4 sm:py-6 sm:bg-deposit">
          <div className="flex justify-center sm:justify-end">
            <button className="" onClick={() => navigate("/groups")}>
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)",
                }}
                className="rounded-full w-12 md:w-16 h-12 md:h-16 flex items-center justify-center bg-deposit"
              >
                <UserGroupIcon
                  className="w-5 md:w-7 text-xs stroke-1"
                  stroke="#B78103"
                />
              </div>
            </button>
          </div>
          <div className="mt-4 text-sm sm:text-lg text-center sm:text-left font-semibold text-[#7a4f01]">
            Group Savings
          </div>
        </li>
      </ul>
    </div>
  );
};

export default QuickActions;

import React from "react";
import ReactDOM from "react-dom";

export default function Backdrop(props) {
  return ReactDOM.createPortal(
    <div
      className="md:block hidden fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-30"
      onClick={props.onClick}
    ></div>,
    document.getElementById("backdrop-hook")
  );
}

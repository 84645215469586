import React from "react";
import MainNavigation from "components/Navbars/MainNavigation";
import MainFooter from "components/Footer/MainFooter";

import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function FAQ() {
  const filters = [
    {
      id: "color",
      name: "What is Adashi?",
      options: [
        {
          value:
            "Adashi allows you to save your money by depositing cash through our agents just like the way you save with traditional thrift collectors, however, with Adashi your money is safe and secured. You can track and see the movement of your money from your phone. Adashi helps you to live a stress-free life without worrying about someone running with your money.",
          label:
            "Adashi allows you to save your money by depositing cash through our agents just like the way you save with traditional thrift collectors, however, with Adashi your money is safe and secured. You can track and see the movement of your money from your phone. Adashi helps you to live a stress-free life without worrying about someone running with your money.",
        },
      ],
    },
    {
      id: "color",
      name: "Why should I choose Adashi?",
      options: [
        {
          value: "Adashi is suitable for anyone who:",
          label: "Adashi is suitable for anyone who:",
        },
      ],
    },
    {
      id: "color",
      name: "How do I save on Adashi?",
      options: [
        {
          value:
            "Just like the way you save with local thrift collectors, Adashi agents come to you for collections. You get an instant SMS alert when you save or withdraw with Adashi. This enables transparency and lets you track your money.",
          label:
            "Just like the way you save with local thrift collectors, Adashi agents come to you for collections. You get an instant SMS alert when you save or withdraw with Adashi. This enables transparency and lets you track your money. ",
        },
      ],
    },
    {
      id: "How Secure is my information?",
      name: "How Secure is my information?",
      options: [
        {
          value:
            "Adashi was built with the security of your information in mind. Our unique security system encrypts your data and secures it safely.  None of your information is stored with us as we work with a PCI-DSS compliant payment processor. The PCI-DSS is a standard guide, globally, that all reputable payment processors must adhere to.",
          label:
            "Adashi was built with the security of your information in mind. Our unique security system encrypts your data and secures it safely.  None of your information is stored with us as we work with a PCI-DSS compliant payment processor. The PCI-DSS is a standard guide, globally, that all reputable payment processors must adhere to.",
        },
      ],
    },
    {
      id: "How safe is my Money?",
      name: "How safe is my Money?",
      options: [
        {
          value:
            "Adashi is built on multiple security and safety layers embedded in our user experience, we ensure security in these areas:",
          label:
            "Adashi is built on multiple security and safety layers embedded in our user experience, we ensure security in these areas:",
        },
      ],
    },
    {
      id: "Do you have a license?",
      name: "Do you have a license?",
      options: [
        {
          value:
            "Adashi though in the process of concluding its registration with relevant regulators works with partners who are duly registered with the Central Bank of Nigeria (CBN), Securities and Exchange Commission (SEC) and Microfinance Banks that are insured by the Nigerian Deposit Insurance Corporation (NDIC). All our activities are in full compliance with regulatory requirements for the ultimate protection of our customers.",
          label:
            "Adashi though in the process of concluding its registration with relevant regulators works with partners who are duly registered with the Central Bank of Nigeria (CBN), Securities and Exchange Commission (SEC) and Microfinance Banks that are insured by the Nigerian Deposit Insurance Corporation (NDIC). All our activities are in full compliance with regulatory requirements for the ultimate protection of our customers.",
        },
      ],
    },
    {
      id: "Can I save in a group?",
      name: "Can I save in a group?",
      options: [
        {
          value:
            "Yes, Adashi agents can group users into saving peers and manage your savings and withdrawal according to the timelines you choose. ",
          label:
            "Yes, Adashi agents can group users into saving peers and manage your savings and withdrawal according to the timelines you choose. ",
        },
      ],
    },
    {
      id: "How much do you charge?",
      name: "How much do you charge?",
      options: [
        {
          value:
            "Just like in traditional adashi, ajo or esusu, the agents keep the first deposit you make while they manage your deposits and withdrawal for the rest of the month.",
          label:
            "Just like in traditional adashi, ajo or esusu, the agents keep the first deposit you make while they manage your deposits and withdrawal for the rest of the month.",
        },
      ],
    },
  ];

  return (
    <div>
      <MainNavigation />

      <section className="my-4 py-3 md:py-4 px-4 md:px-20 md:mt-20 mx-auto max-w-[1280px] mb-20">
        <div className="mb-10">
          <h2 className="text-2xl md:text-3xl text-center text-blackText font-semibold mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-center">Having Problems? Contact us.</p>
        </div>

        <div className="">
          <div className="mx-auto max-w-4xl">
            {/* Filters */}
            <form className="lg:block">
              {filters.map((section) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="border-b border-gray-200 py-6"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-3 flow-root">
                        <Disclosure.Button className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-900">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <svg
                                className="h-5 w-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M20 12H4"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="w-5 h-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                              </svg>
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6">
                        <div className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="text-sm text-gray-600"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </form>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <Link
            to="/agent/login"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded text-primary border-primary hover:bg-transparent hover:border-primary"
          >
            Get started now
          </Link>
        </div>
      </section>

      <MainFooter />
    </div>
  );
}

import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/outline";

import avatar from "assets/images/user.gif";
import { useNavigate } from "react-router-dom";
import { useMe } from "../../providers/useMe";

const UserDropdown = () => {
  const { logoutUser } = useMe();

  const navigate = useNavigate();

  const logOut = async () => {
    const sure = window.confirm("Are you sure you want to logout?");
    if (!sure) return;
    await logoutUser();
    navigate("/", { replace: true });
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="items-center flex justify-center">
          <span className="w-10 h-10 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border shadow-sm"
              src={avatar}
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white border divide-y divide-gray-100 rounded">
          {/* <div className="p-2">
            <Menu.Item>
              <Link
                to="/settings"
                className="flex items-center w-full px-2 py-2 space-x-2 text-sm text-gray-800 rounded hover:bg-blue-600 hover:text-white group"
              >
                <CogIcon aria-hidden="true" className="w-5 h-5" />
                <span>Settings</span>
              </Link>
            </Menu.Item>
          </div>
          <div className="p-2">
            <Menu.Item>
              <Link
                to="/"
                className="flex items-center w-full px-2 py-2 space-x-2 text-sm text-gray-800 rounded hover:bg-blue-600 hover:text-white group"
              >
                <DesktopComputerIcon aria-hidden="true" className="w-5 h-5" />
                <span>Landing Page</span>
              </Link>
            </Menu.Item>
          </div> */}
          <div className="p-2">
            <Menu.Item>
              <button
                className="flex items-center w-full px-2 py-2 space-x-2 text-sm text-gray-800 rounded hover:bg-blue-600 hover:text-white group"
                onClick={logOut}
              >
                <LogoutIcon aria-hidden="true" className="w-5 h-5" />
                <span>Logout</span>
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserDropdown;

import { ClipboardIcon } from "@heroicons/react/outline";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { SortableElement } from "react-sortable-hoc";
import correct from "assets/icons/correct.svg";

const GroupMember = ({ value, not_received }) => {
  return (
    <tr className="hover:bg-light cursor-pointer">
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
        <div className="flex items-center">
          {!not_received && <img src={correct} alt="..." />}
          <span className="mx-2 text-sm">{value?.saverId}</span>
          <CopyToClipboard
            text={value?.saverId}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button>
              <ClipboardIcon className="w-6 h-6 text-gray-900" />
            </button>
          </CopyToClipboard>
        </div>
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
        {value?.firstName} {value?.lastName}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
        {value?.paymentPlan?.payment?.map((p, idx) => (
          <span key={idx}>✅</span>
        ))}
      </td>
    </tr>
  );
};

export default SortableElement(GroupMember);

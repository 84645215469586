import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PaperClipIcon } from "@heroicons/react/outline";
import { useVerification } from "../../../hooks/data/useVerification";

const DocInfo = ({ user, refetch }) => {
  const [docInfo, setDocInfo] = useState({
    idType: "",
    idNumber: "",
    idFile: null,
    idPreview: null,
    isIdFileValid: false,
    billFile: null,
    billPreview: null,
    isBillFileValid: false,
    passportFile: null,
    passportPreview: null,
    isPassportFileValid: false,
    signatureFile: null,
    signaturePreview: null,
    isSignatureFileValid: false,
    loading: false,
  });

  const { refetch: refetchVeri } = useVerification();

  useEffect(() => {
    setDocInfo({
      idType: user?.identity?.identityType,
      idNumber: user?.identity?.identityNumber,
    });
  }, [user]);

  const handleDocInfo = (event) => {
    setDocInfo({ ...docInfo, [event.target.name]: event.target.value });
  };
  const handleDocFile = async (event) => {
    const str = `is${[event.target.name]}Valid`;
    const uppercase = str.charAt(2).toUpperCase();
    const mixed = "is" + uppercase + str.slice(3);
    const isValid = [`${mixed}`].toString();

    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      if (event.target.files[0].size > 10_000_000) {
        alert("File is too big!. Should be less than 10MB");
        return;
      }
      pickedFile = event.target.files[0];
      let obj = { ...docInfo };
      obj[`${isValid}`] = true;
      obj[event.target.name] = pickedFile;
      await setDocInfo(obj);
    } else {
      setDocInfo({ ...docInfo, [`${isValid}`]: false });
    }
  };
  const docInfoSubmit = async (event) => {
    event.preventDefault();

    setDocInfo({ ...docInfo, loading: true });

    if (
      !docInfo.idFile ||
      !docInfo.billFile ||
      !docInfo.signatureFile ||
      !docInfo.passportFile
    ) {
      setDocInfo({ ...docInfo, loading: false });
      return;
    }

    const formData = new FormData();
    formData.append("idType", docInfo.idType);
    formData.append("idNumber", docInfo.idNumber);
    formData.append("idFile", docInfo.idFile);
    formData.append("billFile", docInfo.billFile);
    formData.append("passportFile", docInfo.passportFile);
    formData.append("signatureFile", docInfo.signatureFile);

    try {
      const response = await axios({
        method: "patch",
        url: `/agent/doc-info`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success(response.data.message);
      setDocInfo({ ...docInfo, loading: false });
      refetch();
      refetchVeri();
    } catch (error) {
      console.log(error);
      setDocInfo({ ...docInfo, loading: false });
      if (error.response) toast.error(error.response.data);
    }
  };

  return (
    <form onSubmit={docInfoSubmit}>
      <div className="flex flex-col md:flex-row justify-between mt-5 md:mb-5">
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="idType"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Identity card type
          </label>
          <select
            className="form-select block w-full px-3 rounded border text-sm md:h-12 h-10 border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
            name="idType"
            value={docInfo.idType}
            onChange={handleDocInfo}
            onBlur={handleDocInfo}
            required
          >
            <option />

            <option className="text-sm" value="international passport">
              International passport
            </option>
            <option className="text-sm" value="national identity card">
              National Identity card
            </option>
            <option className="text-sm" value="driving license">
              Driving license
            </option>
          </select>
        </div>
        <div className="relative w-full md:w-[48%] mb-3">
          <label
            htmlFor="idNumber"
            className="text-sm font-medium text-gray-900 block mb-2 text-left"
          >
            Identity card number
          </label>
          <input
            type="text"
            className="border border-solid border-gray-300 px-3 py-2 md:py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full md:h-12 h-10"
            placeholder="Identity card number"
            style={{ transition: "all .15s ease" }}
            name="idNumber"
            value={docInfo.idNumber}
            onChange={handleDocInfo}
            required
            minLength={10}
          />
        </div>
      </div>
      <div className="relative w-full mb-3 md:mb-5">
        <label
          htmlFor="uploadId"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          Upload your Identity card.
        </label>
        <div className="bg-white">
          <div className="max-w-md rounded-lg overflow-hidden w-full md:max-w-xl">
            <div className="md:flex w-full">
              <div className="w-full">
                <div className="relative h-40 rounded border-dashed border-2 border-primary bg-gray-100 flex justify-center items-center">
                  <div className="absolute">
                    <div className="flex flex-col items-center">
                      <i className="fa fa-folder-open fa-4x text-blue-700"></i>
                      <span className="block text-gray-400 font-normal">
                        Attach you files here
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    className="h-full w-full opacity-0"
                    name="idFile"
                    accept="image/png, image/jpeg, image/jpg, .pdf"
                    onChange={handleDocFile}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(docInfo.idFile || user?.identity?.kycDocs?.idCard) && (
          <div className="w-full md:max-w-xl mt-4 bg-white">
            <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
              <div className="w-0 flex-1 flex items-center">
                <div className="w-6 h-6">
                  <PaperClipIcon />
                </div>
                <span className="ml-2 flex-1 w-0 truncate">
                  {docInfo.idFile?.name || user?.identity?.kycDocs?.idCard}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button className="font-medium text-primary hover:opacity-70">
                  X
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="relative w-full mb-3 md:mb-5">
        <label
          htmlFor="uploadID"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          Upload your Utility bill.
        </label>
        <div className="bg-white">
          <div className="max-w-md rounded-lg overflow-hidden w-full md:max-w-xl">
            <div className="md:flex w-full">
              <div className="w-full">
                <div className="relative h-40 rounded border-dashed border-2 border-primary bg-gray-100 flex justify-center items-center">
                  <div className="absolute">
                    <div className="flex flex-col items-center">
                      <i className="fa fa-folder-open fa-4x text-blue-700"></i>{" "}
                      <span className="block text-gray-400 font-normal">
                        Attach you files here
                      </span>{" "}
                    </div>
                  </div>
                  <input
                    type="file"
                    className="h-full w-full opacity-0"
                    name="billFile"
                    accept="image/png, image/jpeg, image/jpg, .pdf"
                    onChange={handleDocFile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(docInfo.billFile || user?.identity?.kycDocs?.utility_bill) && (
          <div className="w-full md:max-w-xl mt-4 bg-white">
            <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
              <div className="w-0 flex-1 flex items-center">
                <div className="w-6 h">
                  <PaperClipIcon />
                </div>
                <span className="ml-2 flex-1 w-0 truncate">
                  {docInfo.billFile?.name ||
                    user?.identity?.kycDocs?.utility_bill}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button
                  href="#"
                  className="font-medium text-primary hover:opacity-70"
                >
                  X
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="relative w-full mb-3 md:mb-5">
        <label
          htmlFor="uploadPassport"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          Upload your Passport photography.
        </label>
        <div className="bg-white">
          <div className="max-w-md rounded-lg overflow-hidden w-full md:max-w-xl">
            <div className="md:flex w-full">
              <div className="w-full">
                <div className="relative h-40 rounded border-dashed border-2 border-primary bg-gray-100 flex justify-center items-center">
                  <div className="absolute">
                    <div className="flex flex-col items-center">
                      <i className="fa fa-folder-open fa-4x text-blue-700"></i>{" "}
                      <span className="block text-gray-400 font-normal">
                        Attach you files here
                      </span>{" "}
                    </div>
                  </div>
                  <input
                    type="file"
                    className="h-full w-full opacity-0"
                    name="passportFile"
                    accept="image/png, image/jpeg, image/jpg, .pdf"
                    onChange={handleDocFile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(docInfo.passportFile || user?.identity?.kycDocs?.passport) && (
          <div className="w-full md:max-w-xl mt-4 bg-white">
            <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
              <div className="w-0 flex-1 flex items-center">
                <div className="w-6 h">
                  <PaperClipIcon />
                </div>
                <span className="ml-2 flex-1 w-0 truncate">
                  {docInfo.passportFile?.name ||
                    user?.identity?.kycDocs?.passport}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button
                  href="#"
                  className="font-medium text-primary hover:opacity-70"
                >
                  X
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="relative w-full mb-3 md:mb-5">
        <label
          htmlFor="uploadID"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          Upload your Signature.
        </label>
        <div className="bg-white">
          <div className="max-w-md rounded-lg overflow-hidden w-full md:max-w-xl">
            <div className="md:flex w-full">
              <div className="w-full">
                <div className="relative h-40 rounded border-dashed border-2 border-primary bg-gray-100 flex justify-center items-center">
                  <div className="absolute">
                    <div className="flex flex-col items-center">
                      {" "}
                      <i className="fa fa-folder-open fa-4x text-blue-700"></i>{" "}
                      <span className="block text-gray-400 font-normal">
                        Attach you files here
                      </span>{" "}
                    </div>
                  </div>{" "}
                  <input
                    type="file"
                    className="h-full w-full opacity-0"
                    name="signatureFile"
                    accept="image/png, image/jpeg, image/jpg, .pdf"
                    onChange={handleDocFile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {(docInfo.signatureFile || user?.identity?.kycDocs?.signature) && (
          <div className="w-full md:max-w-xl mt-4 bg-white">
            <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
              <div className="w-0 flex-1 flex items-center">
                <div className="w-6 h-6">
                  <PaperClipIcon />
                </div>
                <span className="ml-2 flex-1 w-0 truncate">
                  {docInfo.signatureFile?.name ||
                    user?.identity?.kycDocs?.signature}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button className="font-medium text-primary hover:opacity-70">
                  X
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-right mt-5">
        <button
          type="submit"
          className="bg-primary text-white text-base py-2 px-4 font-medium rounded"
          disabled={docInfo.loading}
        >
          {docInfo.loading ? "..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default DocInfo;

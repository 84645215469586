import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "../axios";

import toast from "react-hot-toast";

import failed from "assets/icons/failed.svg";

import Collapsible from "components/Accordion/Collapsible";
import { DashboardLayout } from "layouts";
import { useVerification } from "hooks/data/useVerification";
import { Disclosure } from "@headlessui/react";
import BasicInfo from "components/Forms/BasicInfo";
import OtherInfo from "components/Forms/OtherInfo";
import DocInfo from "components/Forms/DocInfo";
import { fetchUserDetails } from "../apicore/index";

const VerifiedIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9524 10L18.6286 7.34286L18.9524 3.82857L15.5143 3.04762L13.7143 0L10.4762 1.39048L7.2381 0L5.43809 3.0381L2 3.80952L2.32381 7.33333L0 10L2.32381 12.6571L2 16.181L5.43809 16.9619L7.2381 20L10.4762 18.6L13.7143 19.9905L15.5143 16.9524L18.9524 16.1714L18.6286 12.6571L20.9524 10ZM7.98095 13.819L5.71429 11.5333C5.626 11.4452 5.55595 11.3406 5.50816 11.2254C5.46037 11.1101 5.43577 10.9866 5.43577 10.8619C5.43577 10.7372 5.46037 10.6137 5.50816 10.4985C5.55595 10.3832 5.626 10.2786 5.71429 10.1905L5.78095 10.1238C6.15238 9.75238 6.7619 9.75238 7.13333 10.1238L8.66667 11.6667L13.5714 6.75238C13.9429 6.38095 14.5524 6.38095 14.9238 6.75238L14.9905 6.81905C15.3619 7.19048 15.3619 7.79048 14.9905 8.1619L9.35238 13.819C8.9619 14.1905 8.3619 14.1905 7.98095 13.819V13.819Z"
      fill="currentColor"
    />
  </svg>
);

export default function AgentVerification() {
  const { data, isLoading, refetch } = useQuery(
    "user-details",
    fetchUserDetails
  );

  const user = data?.data.data;

  const { data: verification, refetch: refetchVeri } = useVerification();

  const [requestLoading, setRequestLoading] = useState(false);

  const handleRequestVeri = async () => {
    try {
      setRequestLoading(true);
      const response = await axios.get("/agent/request/approval");
      toast.success(response.data.message);
      setRequestLoading(false);
      refetchVeri();
    } catch (error) {
      console.log(error);
      setRequestLoading(false);
      if (error.response) {
        toast.error(error.response.message);
      }
    }
  };
  const handleReRequestVeri = async () => {
    try {
      setRequestLoading(true);
      const response = await axios.get("/agent/re-request/approval");
      toast.success(response.data.message);
      setRequestLoading(false);
      refetchVeri();
    } catch (error) {
      console.log(error);
      setRequestLoading(false);
      if (error.response) {
        toast.error(error.response.message);
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.isAgentApproved) navigate("/dashboard");
  }, [user, navigate]);

  const basicInfoValid =
    user?.firstName?.length > 0 &&
    user?.lastName?.length > 0 &&
    user?.email?.length > 0 &&
    user?.phoneNumber?.length > 0 &&
    user?.identity?.birthday?.length > 0 &&
    user?.identity?.birthday?.length > 0 &&
    user?.identity?.bvn?.length > 0;

  const otherInfoValid =
    user?.identity?.homeAddress?.addressLine1?.length > 0 &&
    user?.identity?.homeAddress?.city?.length > 0 &&
    user?.identity?.homeAddress?.lga?.length > 0 &&
    user?.identity?.stateOfOrigin?.length > 0 &&
    user?.identity?.lgaOfOrigin?.length > 0 &&
    user?.nextOfKin?.name?.length > 0 &&
    user?.nextOfKin?.phoneNumber?.length > 0 &&
    user?.identity?.userCategory?.length > 0;

  const docInfoValid =
    user?.identity?.identityNumber?.toString().length > 0 &&
    user?.identity?.identityType?.length > 0 &&
    user?.identity?.kycDocs?.idCard?.length > 0 &&
    user?.identity?.kycDocs?.signature?.length > 0 &&
    user?.identity?.kycDocs?.utility_bill?.length > 0;

  return (
    <DashboardLayout>
      <div className="relative bg-light min-h-screen">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="mb-8">
            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
              Getting Started ✨
            </h1>
          </div>
        </div>
        {/* title */}
        <div className="relative pb-28">
          <div className="px-4 md:px-8">
            {!isLoading && (
              <div className="w-full md:w-4/6 space-y-4">
                {/* Basic Info */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between bg-white px-4 py-2 text-left text-sm font-medium text-dark hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <div className="title">
                          <h1 className="text-lg font-semibold mb-2 flex items-center">
                            <div
                              className={
                                basicInfoValid ? "text-primary" : "text-light_"
                              }
                            >
                              <VerifiedIcon />
                            </div>

                            <span className="ml-2">Basic Information </span>
                          </h1>
                          <p className="text-sm">
                            Please provide your basic information
                          </p>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <BasicInfo user={user} refetch={refetch} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {/* End Basic Info */}

                {/* Other Info */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between bg-white px-4 py-2 text-left text-sm font-medium text-dark hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <div className="title">
                          <h1 className="text-lg font-semibold mb-2 flex items-center">
                            <div
                              className={
                                otherInfoValid ? "text-primary" : "text-light_"
                              }
                            >
                              <VerifiedIcon />
                            </div>

                            <span className="ml-2">Other Information</span>
                          </h1>
                          <p className="text-sm">
                            Please provide your other information
                          </p>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                        <OtherInfo user={user} refetch={refetch} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {/* End Other Info */}

                {/* Document Info */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between bg-white px-4 py-2 text-left text-sm font-medium text-dark hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-light focus-visible:ring-opacity-75">
                        <div className="title">
                          <h1 className="text-lg font-semibold mb-2 flex items-center">
                            <div
                              className={
                                docInfoValid ? "text-primary" : "text-light_"
                              }
                            >
                              <VerifiedIcon />
                            </div>

                            <span className="ml-2">Document Information</span>
                          </h1>
                          <p className="text-sm">
                            Please provide your identity information
                          </p>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                        <DocInfo user={user} refetch={refetch} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {/* End Document Info */}

                {/* Complete Request */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full justify-between bg-white px-4 py-2 text-left text-sm font-medium text-dark hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-light focus-visible:ring-opacity-75"
                        disabled={
                          !basicInfoValid || !otherInfoValid || !docInfoValid
                        }
                      >
                        <div className="title">
                          <h1 className="text-lg font-semibold mb-2 flex items-center">
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.9524 10L18.6286 7.34286L18.9524 3.82857L15.5143 3.04762L13.7143 0L10.4762 1.39048L7.2381 0L5.43809 3.0381L2 3.80952L2.32381 7.33333L0 10L2.32381 12.6571L2 16.181L5.43809 16.9619L7.2381 20L10.4762 18.6L13.7143 19.9905L15.5143 16.9524L18.9524 16.1714L18.6286 12.6571L20.9524 10ZM7.98095 13.819L5.71429 11.5333C5.626 11.4452 5.55595 11.3406 5.50816 11.2254C5.46037 11.1101 5.43577 10.9866 5.43577 10.8619C5.43577 10.7372 5.46037 10.6137 5.50816 10.4985C5.55595 10.3832 5.626 10.2786 5.71429 10.1905L5.78095 10.1238C6.15238 9.75238 6.7619 9.75238 7.13333 10.1238L8.66667 11.6667L13.5714 6.75238C13.9429 6.38095 14.5524 6.38095 14.9238 6.75238L14.9905 6.81905C15.3619 7.19048 15.3619 7.79048 14.9905 8.1619L9.35238 13.819C8.9619 14.1905 8.3619 14.1905 7.98095 13.819V13.819Z"
                                fill="#D7D7D7"
                              />
                            </svg>

                            <span className="ml-2">Complete Request</span>
                          </h1>

                          <p className="text-sm">
                            Please request for verification
                          </p>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                        {!user.isAgentApproved &&
                        verification?.data?.feedback?.length > 0 ? (
                          <div>
                            <div className="w-full flex items-center justify-center">
                              <div className="text-center mt-5">
                                <img
                                  src={failed}
                                  alt="..."
                                  className="mb-5 text-center mx-auto"
                                />
                                <h3 className="mb-2 text-lg font-medium text-blackText">
                                  Verification Failed
                                </h3>
                                <p className="text-dark mb-2">
                                  Your account verification failed.
                                </p>
                                <p className="text-dark">
                                  {verification?.data?.feedback[0].content}
                                </p>
                              </div>
                            </div>
                            <div className="text-center mt-5">
                              <button
                                className="bg-primary text-white text-base py-2 px-4 font-medium rounded"
                                onClick={handleReRequestVeri}
                              >
                                Retry verification
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center mt-5">
                            <button
                              className={
                                "bg-primary text-white text-base py-2 px-4 font-medium rounded " +
                                (requestLoading || verification
                                  ? "bg-opacity-30"
                                  : "bg-opacity-100")
                              }
                              disabled={requestLoading || verification}
                              onClick={handleRequestVeri}
                            >
                              {requestLoading
                                ? "..."
                                : verification
                                ? "Verification Already requested"
                                : " Request verification"}
                            </button>

                            {verification && (
                              <p className="text-sm mt-4">
                                We are currently verifying your account, This
                                can take up to 24 - 48 hours. please check your
                                inbox for a notification message
                              </p>
                            )}
                          </div>
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { daysToWeeks } from "date-fns";
import { ArchiveIcon, TrashIcon } from "@heroicons/react/outline";
// import client from "../../../data/client";
import axios from "../../../axios";
import { API_ENDPOINTS } from "../../../data/client/endpoints";
import toast from "react-hot-toast";

const SavingGroup = ({ group }) => {
  const navigate = useNavigate();

  const handleDeleteSavingsGroup = () => {
    const text = "Are you sure you want to delete this group!\nYes or Cancel.";
    if (window.confirm(text) !== true) return;

    axios
      .delete(`${API_ENDPOINTS.SAVINGGROUPS_DELETE}/${group._id}`)
      .then((res) => toast.success("Savings group deleted"))
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message ?? "Error deleting group");
      });
  };

  const handleArchiveSavingsGroup = () => {
    const text = "Are you sure you want to archive this group!\nYes or Cancel.";
    if (window.confirm(text) !== true) return;

    axios
      .post(`${API_ENDPOINTS.SAVINGGROUPS_ARCHIVE}/${group._id}`)
      .then((res) => toast.success(res?.data?.message))
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message ?? "Error deleting group");
      });
  };

  return (
    <li className="md:border border-gray-300 md:p-4 rounded flex content-between justify-center flex-col md:flex-row md:justify-between md:items-center">
      <div className="w-full md:w-1/5 md:pr-2 mb-3 md:mb-0">
        <div className="md:border-r border-gray-200 md:flex-col justify-between items-center md:justify-start md:items-start flex md:pr-2">
          <span className="block md:mb-2 text-sm">Group Name</span>
          <span className="font-semibold">{group?.groupName}</span>
        </div>
      </div>
      <div className="w-full md:w-4/5 flex flex-col md:flex-row content-between justify-center md:justify-between md:items-center">
        <div className="md:border-r md:mb-0 mb-2 border-gray-200 md:pr-2 md:flex-col flex justify-between items-center md:justify-start md:items-start">
          <span className="block md:mb-2 text-sm">Withdraw Frequency</span>
          <span className="font-semibold capitalize">
            {group?.frequency} ({group?.duration / group?.slotsNumber})
          </span>
        </div>
        <div className="md:border-r border-gray-200 md:mb-0 mb-2 md:pr-2 md:flex-col flex justify-between items-center md:justify-start md:items-start">
          <span className="block md:mb-2 text-sm">Members</span>
          <span className="font-semibold">{group?.slotsNumber}</span>
        </div>
        <div className="border-r border-gray-200 md:mb-0 mb-2 md:pr-2 md:flex-col flex justify-between items-center md:justify-start md:items-start">
          <span className="block md:mb-2 text-sm">Duration</span>
          <span className="font-semibold">
            {daysToWeeks(group.duration)} Weeks
          </span>
        </div>
        <div className="border-r border-gray-200 md:mb-0 mb-2 md:pr-2 md:flex-col flex justify-between items-center md:justify-start md:items-start">
          <span className="block md:mb-2 text-sm">Status</span>
          <span className="font-semibold text-yellow-500">{group.status}</span>
        </div>
        <div className="flex items-center justify-between md:justify-start">
          <button
            className="py-2 px-3 border border-primary bg-transparent text-primary font-medium rounded hover:bg-primary hover:text-white md:mr-4"
            onClick={() => navigate(`/groups/${group._id}`)}
          >
            Edit Circle
          </button>

          <div className="flex">
            <button onClick={handleDeleteSavingsGroup}>
              <TrashIcon className="w-6 h-6" />
            </button>
            <div className="mx-4"></div>
            <button onClick={handleArchiveSavingsGroup}>
              <ArchiveIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

const SavingsGroups = ({ groups }) => {
  return (
    <ul className="space-y-6">
      {groups.map((group) => (
        <SavingGroup group={group} key={group._id} />
      ))}
    </ul>
  );
};

export default SavingsGroups;

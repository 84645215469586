import { getVerificationStatus } from "../../apicore";
import { useQuery } from "react-query";

export const useVerification = () => {
  const { data, isLoading, refetch } = useQuery(
    "verification",
    getVerificationStatus,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return { data, isLoading, refetch };
};

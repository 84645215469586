import { useQuery } from "react-query";
import { fetchAllBanks } from "../../apicore";

export const useNigBanks = () => {
  const { data, isLoading: banksLoading } = useQuery(
    "all-banks",
    fetchAllBanks,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return { data: data?.data, banksLoading };
};

import React from "react";

import avatar from "assets/images/user.svg";
// import camera from "assets/icons/camera.svg";

export default function Profile({ show, user }) {
  return (
    <div className={`px-4 sm:px-6 lg:px-8 mx-auto w-full block`}>
      <form className="bg-white px-4 py-4 border rounded">
        <h3 className="font-semibold mb-5 text-lg">Personal Information</h3>
        <div className="flex justify-between flex-col-reverse md:flex-row">
          <div className="left w-full md:w-2/3">
            <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-4 xl:-mx-4">
              <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                <div className="mb-6">
                  <label
                    htmlFor="firstName"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                    value={user.firstName}
                    readOnly
                  />
                </div>
              </div>

              <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                <div className="mb-6">
                  <label
                    htmlFor="lastName"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                    value={user.lastName}
                    readOnly
                  />
                </div>
              </div>

              <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                    value={user.email}
                    readOnly
                  />
                </div>
              </div>

              <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2 xl:my-4 xl:px-4 xl:w-1/2">
                <div className="mb-6">
                  <label
                    htmlFor="phone"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Phone number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full p-2.5 focus:outline-none focus:ring"
                    value={user.phoneNumber}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right w-full md:w-3/12 mt-7 md:mb-0 mb-5 flex justify-center md:block">
            <div className="relative flex justify-center">
              <img
                src={avatar}
                alt="..."
                className="md:w-48 rounded-full w-36 border"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

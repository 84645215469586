import React, { useState } from "react";
import { useDebounce } from "libs/Hooks";
import axios from "../../axios";

import successful from "assets/icons/checked.svg";
import correct from "assets/icons/correct.svg";
import save from "assets/icons/save.svg";

import toast from "react-hot-toast";

export default function Savings({ refetchBalance }) {
  const [showModal, setShowModal] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    accountId: "",
    amount: "",
    pin: "",
  });
  const [disable, setDisable] = useState(true);
  const [currentSaver, setCurrentSaver] = useState(null);

  useDebounce(() => verifyAccountId(), 1000, [values.accountId]);

  const verifyAccountId = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`/saver/saver/${values.accountId}`);

      document.getElementById("accountId").classList.remove("border-red-500");
      document.getElementById("accountId").classList.add("border-green-500");
      setCurrentSaver(response.data.data);

      setLoading(false);
      setDisable(false);
    } catch (error) {
      if (document.getElementById("accountId")) {
        document
          .getElementById("accountId")
          .classList.remove("border-green-500");
        document.getElementById("accountId").classList.add("border-red-500");
      }

      setCurrentSaver(null);
      setLoading(false);
      setDisable(true);
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      await axios.post(`/transactions/savings`, {
        amount: parseInt(values.amount, 10),
        accountId: values.accountId,
        pin: values.pin,
      });

      setValues({ accountId: "", amount: "", pin: "" });
      setSuccess(true);
      toast.success("Successfully saved!");
      refetchBalance();
      setLoading(false);
      setDisable(true);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
  };

  const resetSavings = () => {
    setShowModal(false);
    setSuccess(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <div
          style={{
            backgroundImage:
              "linear-gradient(135deg, rgba(0, 123, 85, 0) 0%, rgba(0, 123, 85, 0.24) 100%)",
          }}
          className="bg-savings rounded-full w-12 md:w-16 h-12 md:h-16 flex items-center justify-center"
        >
          <img src={save} alt="..." className="w-5 md:w-7" />
        </div>
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid border-borderbg rounded-t">
                  <h3 className="text-base font-medium text-blackcolor leading-5">
                    Make savings
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                {success ? (
                  <div className="py-5">
                    <div className="w-full flex items-center justify-center">
                      <img src={successful} alt="..." />
                    </div>
                    <p className="text-sm text-center mt-4 px-4">
                      Congratulations. 🎉 Saving made successfully
                    </p>
                  </div>
                ) : (
                  <div className="relative p-6 flex-auto">
                    <form id="addressForm" onSubmit={handleSubmit}>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="amount"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Amount
                        </label>
                        <input
                          type="number"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Amount"
                          style={{ transition: "all .15s ease" }}
                          name="amount"
                          id="amount"
                          autoComplete="off"
                          value={values.amount}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="relative w-full mb-5">
                        <label
                          htmlFor="accountId"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Account ID
                        </label>
                        <input
                          type="text"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Account ID"
                          style={{ transition: "all .15s ease" }}
                          name="accountId"
                          id="accountId"
                          value={values.accountId}
                          onChange={handleChange}
                          autoComplete="off"
                          required
                        />
                        {currentSaver && (
                          <div className="flex justify-between mt-2">
                            <small className="text-xs text-green-600">
                              {`${currentSaver.firstName} ${currentSaver.lastName}`}
                            </small>
                            <img src={correct} alt="..." />
                          </div>
                        )}
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="pin"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Your PIN
                        </label>
                        <input
                          type="number"
                          className={`border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 ${
                            disable ? "bg-gray-200" : "bg-white"
                          } rounded text-sm shadow focus:outline-none focus:ring w-full`}
                          placeholder="Enter your PIN"
                          style={{ transition: "all .15s ease" }}
                          name="pin"
                          id="pin"
                          autoComplete="off"
                          value={values.pin}
                          onChange={handleChange}
                          disabled={disable}
                          required
                          min={0}
                          max={9999}
                        />
                      </div>
                    </form>
                  </div>
                )}
                {/*footer*/}
                <div className="flex p-6 border-t border-solid rounded-b justify-center">
                  {success ? (
                    <>
                      <button
                        className={`bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal`}
                        onClick={resetSavings}
                      >
                        {loading ? "..." : "Continue"}
                      </button>
                    </>
                  ) : (
                    <button
                      className={`bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal ${
                        disable ? "bg-opacity-70" : "bg-opacity-100"
                      }`}
                      form="addressForm"
                      type="submit"
                      disabled={disable || loading}
                    >
                      {loading ? "..." : "Save"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import React from "react";
import { Tab } from "@headlessui/react";
import { useQuery } from "react-query";
import axios from "../axios";

import Profile from "components/Cards/Profile";
import Security from "components/Cards/Security";
import BankAccount from "components/Cards/BankAccount";
import Notification from "components/Cards/Notification";
import { DashboardLayout } from "layouts";

const fetchUserDetails = () => axios.get(`/user/me`);

const tabs = [
  { name: "Profile", href: "#", count: "52", current: false },
  { name: "Security", href: "#", count: "6", current: false },
  { name: "Notifications", href: "#", count: "4", current: true },
  { name: "Bank Account", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Settings() {
  //Fetch User Details
  const { data, isLoading } = useQuery(
    "user-details",
    () => fetchUserDetails(),
    { staleTime: 10000 }
  );

  const userDetails = data?.data.data;

  return (
    <>
      {isLoading ? (
        <span>...</span>
      ) : (
        <DashboardLayout>
          <div className="relative bg-light min-h-screen">
            <div className="w-full px-4 sm:px-6 lg:px-8">
              {/* <!-- Page header --> */}
              <div className="mb-8">
                {/* <!-- Title --> */}
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Account Settings ✨
                </h1>
              </div>
            </div>

            <Tab.Group>
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
                <div className="block">
                  <div className="border-b border-gray-200">
                    <Tab.List
                      as={"nav"}
                      className="-mb-px flex justify-between md:justify-start md:space-x-8"
                      aria-label="Tabs"
                    >
                      {tabs.map((tab) => (
                        <Tab
                          as={"button"}
                          key={tab.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-primary text-primary"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                              "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )
                          }
                          aria-current={tab.current ? "page" : undefined}
                        >
                          {tab.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                </div>
              </div>
              <Tab.Panels>
                {/* {tabs.map((tab, idx) => (
                  <Tab.Panel></Tab.Panel>
                ))} */}
                <Tab.Panel>
                  <Profile user={userDetails} />
                </Tab.Panel>
                <Tab.Panel>
                  <Security />
                </Tab.Panel>
                <Tab.Panel>
                  <Notification />
                </Tab.Panel>
                <Tab.Panel>
                  <BankAccount user={userDetails} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}

export default Settings;

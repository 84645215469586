import React, { useState, useContext } from "react";
import AuthContext from "context/AuthContext";
import { PlusIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";

export default function Deposit() {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        className="font-medium py-3 px-4 rounded-lg bg-white text-primary flex items-center"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <PlusIcon className="w-6 h-6 hidden md:flex" />
        <span className="md:ml-2">Fund wallet</span>
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid border-borderbg rounded-t">
                  <h3 className="text-base font-medium text-blackcolor leading-5">
                    Fund account
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-dark opacity-75 text-sm">
                    See account information below
                  </p>
                  <div className="space-y-6">
                    <Disclosure defaultOpen={true}>
                      <Disclosure.Button className="py-2 w-full text-left">
                        Safe Haven MFB
                      </Disclosure.Button>
                      <Disclosure.Panel className="">
                        <div className="account-details mt-6">
                          <ul className="w-full md:w-3/4">
                            <li className="flex justify-between mb-6">
                              <span className="text-dark opacity-75 text-sm">
                                Account name
                              </span>
                              <span className="text-sm">
                                {user?.subAccount[0]?.name ||
                                  user?.subAccount?.name}
                              </span>
                            </li>
                            <li className="flex justify-between mb-6">
                              <span className="text-dark opacity-75 text-sm">
                                Account number
                              </span>
                              <span className="text-sm">
                                {user?.subAccount[0]?.number ||
                                  user?.subAccount?.number}
                              </span>
                            </li>
                            <li className="flex justify-between">
                              <span className="text-dark opacity-75 text-sm">
                                Bank Name
                              </span>
                              <span className="text-sm">
                                {user?.subAccount[0]?.bankName ||
                                  user?.subAccount?.bankName}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Disclosure.Panel>
                    </Disclosure>
                    {user?.subAccount.length > 1 ? (
                      <Disclosure>
                        <Disclosure.Button className="py-2 w-full text-left">
                          Providus Bank
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <div className="account-details mt-6">
                            <ul className="w-full md:w-3/4">
                              <li className="flex justify-between mb-6">
                                <span className="text-dark opacity-75 text-sm">
                                  Account name
                                </span>
                                <span className="text-sm">
                                  {user?.subAccount[1]?.name}
                                </span>
                              </li>
                              <li className="flex justify-between mb-6">
                                <span className="text-dark opacity-75 text-sm">
                                  Account number
                                </span>
                                <span className="text-sm">
                                  {user?.subAccount[1]?.number}
                                </span>
                              </li>
                              <li className="flex justify-between">
                                <span className="text-dark opacity-75 text-sm">
                                  Bank Name
                                </span>
                                <span className="text-sm">
                                  {user?.subAccount[1]?.bankName}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Disclosure.Panel>
                      </Disclosure>
                    ) : null}
                  </div>
                </div>
                {/*footer*/}
                <div className="flex p-6 border-t border-solid rounded-b justify-center"></div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PrivateRoute = () => {
  const { user, loading } = useContext(AuthContext);

  const location = useLocation();

  return !user || loading || user.role[0] !== "Agent" ? (
    <Navigate to="/auth/login" replace state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export default PrivateRoute;

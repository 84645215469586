import React, { useState, useContext } from "react";
import AuthContext from "context/AuthContext";
import { useQuery } from "react-query";
import axios from "../axios";

//components
import BalanceCard from "components/Cards/BalanceCard";
import { DashboardLayout } from "layouts";
import SetTrxnPin from "components/Modals/SetTrxnPin";
import QuickActions from "components/Cards/QuickActions";
import { useWalletBalance } from "hooks/data/useWalletBalance";

const fetchAgentTransactions = () => axios.get("/transactions/me");

export default function Dashboard() {
  const { user } = useContext(AuthContext);

  const { walletLoading, walletData, refetchWallet } = useWalletBalance();

  const { isLoading, data } = useQuery("transactions", fetchAgentTransactions);
  const transactions = isLoading ? [] : data?.data.data.transactions;

  const [showModal, setModalVisibility] = useState(false);
  const toggleModal = () => setModalVisibility(!showModal);

  return (
    <DashboardLayout>
      <div className="">
        <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full">
          <div className="mb-5">
            <h1 className="font-semibold text-dark flex items-center">
              👋
              <span className="text-2xl ml-2">Hello, {user.firstName}!</span>
            </h1>
          </div>
          <BalanceCard balance={walletData?.data} loading={walletLoading} />
        </div>

        <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full mt-8">
          <QuickActions refetchBalance={refetchWallet} />
        </div>

        <SetTrxnPin show={showModal} toggle={toggleModal} />

        <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full mt-8 md:mt-10">
          <h3 className="font-semibold mb-5 text-lg text-blackText">
            Recent Transactions
          </h3>
          <div className="w-full mb-12 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Reference number
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Amount
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Purpose
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Date & Time
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading && (
                      <>
                        {transactions.length === 0 ? (
                          <tr className="h-12 min-w-full flex items-center">
                            <td className="text-center w-full min-w-full absolute h-14 flex items-center justify-center bg-light p-0">
                              <span className="">No transaction</span>
                            </td>
                          </tr>
                        ) : (
                          transactions.map((transaction) => (
                            <tr
                              key={transaction.reference}
                              className="hover:bg-light cursor-pointer"
                            >
                              <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm text-left font-normal">
                                {transaction.reference}
                              </th>
                              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                {transaction.amount}
                              </td>
                              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                {transaction.purpose}
                              </td>
                              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                {new Date(
                                  transaction.createdAt
                                ).toLocaleString()}
                              </td>
                              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                                <span
                                  className="px-2 py-1 rounded-full"
                                  style={{
                                    backgroundColor: `${
                                      transaction.status === "Success"
                                        ? "#DEFFE5"
                                        : "#FDEDED"
                                    }`,
                                    color: `${
                                      transaction.status === "Success"
                                        ? "#188F32"
                                        : "#EA3433"
                                    }`,
                                  }}
                                >
                                  {transaction.status}
                                </span>
                              </td>
                            </tr>
                          ))
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

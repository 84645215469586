import React from "react";
import { formatMoney } from "../../helpers";

import qrcode from "assets/images/qr-code.png";

export default function TransactionItem(props) {
  return (
    <div className="min-h-screen h-full w-full md:w-80 bg-light mx-auto fixed right-0 top-0 z-50 py-5 shadow">
      <aside>
        <div className="px-4 md:px-6">
          <div className="flex justify-between items-center mb-6">
            <span className="text-sm">Transaction item</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={props.closeDrawer}
            >
              <path
                d="M6 6L18 18M6 18L18 6L6 18Z"
                stroke="#474B56"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="flex justify-between items-center mb-5">
            <h2 className="text-lg font-semibold text-dark uppercase">
              {props.transaction.reference}
            </h2>
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.228 9C8.777 7.835 10.258 7 12 7C14.21 7 16 8.343 16 10C16 11.4 14.722 12.575 12.994 12.907C12.452 13.011 12 13.447 12 14M12 17H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                  stroke="#474B56"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div className="mb-5 w-20 md:mx-auto">
            <img src={qrcode} alt="..." />
          </div>

          <div className="transaction-meta mb-5">
            <div className="relative mb-4">
              <p className="date-time text-sm text-blackText mb-1">
                Date & Time
              </p>
              <p className="text-dark">
                {new Date(props.transaction.createdAt).toLocaleString()}
              </p>
            </div>
            <div className="relative mb-4">
              <p className="date-time text-sm text-blackText mb-1">
                Saver's Id
              </p>
              <p className="text-dark">{props.transaction.receiver}</p>
            </div>
            <div className="flex justify-between items-center mb-4">
              <div className="relative">
                <p className="date-time text-sm text-blackText mb-1">
                  Transaction type
                </p>
                <p className="text-dark">{props.transaction.transactionType}</p>
              </div>
              <div className="relative">
                <p className="date-time text-sm text-blackText mb-1 text-right">
                  Fees
                </p>
                <p className="text-dark">
                  ₦ {formatMoney(props.transaction.fees)}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <div className="relative">
                <p className="date-time text-sm text-blackText mb-1">
                  Transaction status
                </p>
                <p
                  className="rounded-full px-2 py-1 inline-block capitalize"
                  style={{
                    backgroundColor: `${
                      props.transaction.status === "Success"
                        ? "#DEFFE5"
                        : "#FDEDED"
                    }`,
                    color: `${
                      props.transaction.status === "Success"
                        ? "#188F32"
                        : "#EA3433"
                    }`,
                  }}
                >
                  {props.transaction.status}
                </p>
              </div>

              <div className="relative">
                <p className="date-time text-sm text-blackText mb-1 text-right">
                  Amount
                </p>
                <p className="text-dark">
                  ₦ {formatMoney(props.transaction.amount)}
                </p>
              </div>
            </div>
            <div className="relative">
              <p className="date-time text-sm text-blackText mb-1">
                Description
              </p>
              <p className="text-dark">{props.transaction.description}</p>
            </div>
          </div>

          <div className="flex text-primary justify-center items-center shadow py-2 px-3 bg-white">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.68408 13.3424C8.88608 12.9384 9.00008 12.4824 9.00008 12.0004C9.00008 11.5184 8.88608 11.0624 8.68408 10.6584M8.68408 13.3424C8.38178 13.9467 7.88428 14.4314 7.27217 14.7177C6.66007 15.0041 5.96921 15.0754 5.31152 14.9201C4.65384 14.7648 4.06785 14.3919 3.64849 13.862C3.22914 13.3321 3.00098 12.6761 3.00098 12.0004C3.00098 11.3246 3.22914 10.6686 3.64849 10.1387C4.06785 9.60877 4.65384 9.23596 5.31152 9.08066C5.96921 8.92535 6.66007 8.99665 7.27217 9.283C7.88428 9.56934 8.38178 10.054 8.68408 10.6584M8.68408 13.3424L15.3161 16.6584M8.68408 10.6584L15.3161 7.34236M15.3161 16.6584C14.9602 17.3702 14.9016 18.1943 15.1533 18.9493C15.4049 19.7043 15.9462 20.3284 16.6581 20.6844C17.3699 21.0403 18.194 21.0988 18.949 20.8472C19.704 20.5955 20.3282 20.0542 20.6841 19.3424C21.04 18.6305 21.0986 17.8064 20.8469 17.0514C20.5952 16.2964 20.0539 15.6723 19.3421 15.3164C18.9896 15.1401 18.6059 15.035 18.2128 15.0071C17.8197 14.9792 17.425 15.0289 17.0511 15.1535C16.2961 15.4052 15.672 15.9465 15.3161 16.6584ZM15.3161 7.34236C15.4923 7.69476 15.7362 8.009 16.0339 8.26714C16.3316 8.52527 16.6772 8.72225 17.051 8.84682C17.4248 8.97139 17.8195 9.02111 18.2125 8.99315C18.6055 8.96519 18.9892 8.86009 19.3416 8.68386C19.694 8.50762 20.0082 8.26371 20.2664 7.96603C20.5245 7.66835 20.7215 7.32274 20.846 6.94894C20.9706 6.57514 21.0203 6.18046 20.9924 5.78744C20.9644 5.39442 20.8593 5.01076 20.6831 4.65836C20.3272 3.94665 19.7031 3.40548 18.9482 3.1539C18.1932 2.90232 17.3693 2.96094 16.6576 3.31686C15.9459 3.67278 15.4047 4.29685 15.1531 5.05177C14.9015 5.8067 14.9602 6.63065 15.3161 7.34236Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-2 uppercase font-medium">
              Share Transaction
            </span>
          </div>
        </div>
      </aside>
    </div>
  );
}

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";

export default function Paginator({
  handleNext,
  handlePrevious,
  disabledPrevious,
  disabledNext,
  pagesCount,
  setPage,
  page,
}) {
  return (
    <nav
      className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
      aria-label="Pagination"
    >
      <button
        className={
          "relative inline-flex items-center px-2 py-2 rounded-l-md border bg-white text-sm font-medium hover:bg-gray-50 " +
          (disabledPrevious
            ? "cursor-not-allowed text-gray-500 border-gray-300"
            : "cursor-pointer text-primary border-primary")
        }
        onClick={handlePrevious}
        disabled={disabledPrevious}
      >
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon className="w-5 h-5" />
      </button>
      {
        <select
          value={page}
          onChange={(event) => setPage(Number(event.target.value))}
          className="border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50"
        >
          {Array(pagesCount)
            .fill(null)
            .map((_, index) => {
              return <option key={index}>{index + 1}</option>;
            })}
        </select>
      }
      <button
        className={
          "relative inline-flex items-center px-2 py-2 rounded-r-md border  bg-white text-sm font-medium hover:bg-gray-50 " +
          (disabledNext
            ? "cursor-not-allowed text-gray-500 border-gray-300"
            : "cursor-pointer text-primary border-primary")
        }
        onClick={handleNext}
        disabled={disabledNext}
      >
        <span className="sr-only">Next</span>
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    </nav>
  );
}

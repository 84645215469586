import React, { useState, useEffect } from "react";
import axios from "../axios";

//components
import money from "assets/icons/money.svg";
import withdraw from "assets/icons/withdraw.svg";
import savings from "assets/icons/savings.svg";

import Backdrop from "components/Backdrop/Backdrop";
import TransactionItem from "components/Modals/TransactionItem";
import { formatMoney } from "helpers";
import { DashboardLayout } from "layouts";
import Datepicker from "components/Buttons/Datepicker";
import FilterButton from "components/Buttons/FilterButton";
import { useQuery } from "react-query";
import Paginator from "components/Paginator";

async function getTransactions(page = 0) {
  const response = await axios.get("/transactions/me?size=20&page=" + page);
  return response.data;
}

export default function Transactions() {
  const [earnings, setEarnings] = useState({});
  const [currentTransaction, setCurrentTransaction] = useState({});

  const [page, setPage] = React.useState(1);
  const [pagesCount, setPagesCount] = React.useState(0);

  const result = useQuery(["transactions", page], () => getTransactions(page));
  const { data, isLoading } = result;

  useEffect(() => {
    if (data) {
      setPagesCount(data.pagesCount);
    }
  }, [data]);

  function handlePrevious() {
    setPage((p) => {
      if (Number(p) === 1) return p;
      return Number(p) - 1;
    });
  }

  function handleNext() {
    setPage((p) => {
      if (Number(p) === pagesCount) return Number(p);
      return Number(p) + 1;
    });
  }

  const transactions = isLoading ? [] : data?.data.transactions;

  // const resultDeposit = transactions.filter(
  //   (t) => t.txn_type === "Credit" && t.purpose === "Deposit"
  // );
  // const totalDeposit = resultDeposit.reduce((t, s) => t + s.amount, 0);

  //Total savings
  const resultSavings = transactions.filter((t) => t.purpose === "Savings");
  const totalSavings = resultSavings.reduce((t, s) => t + s.amount, 0);

  //Total Payout
  const resultPayouts = transactions.filter((t) => t.purpose === "Payout");
  const totalPayouts = resultPayouts.reduce((t, p) => t + p.amount, 0);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawer = () => setDrawerIsOpen(true);

  const closeDrawer = () => setDrawerIsOpen(false);

  const handleRowClick = (transaction) => {
    openDrawer();
    setCurrentTransaction(transaction);
  };

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const response = await axios.get("/agent/balance");
        setEarnings(response.data.data.earnings);
      } catch (err) {}
    };
    fetchEarnings();
  }, []);

  return (
    <DashboardLayout>
      {drawerIsOpen && <Backdrop onClick={closeDrawer} />}
      {drawerIsOpen && (
        <TransactionItem
          transaction={currentTransaction}
          closeDrawer={closeDrawer}
        />
      )}
      <div className="">
        {/* title */}
        <div className="relative pb-28">
          <div className="px-4 md:px-6 mx-auto w-full flex mb-10">
            <p className="text-dark text-xl font-semibold md:hidden">
              Transactions
            </p>
          </div>
          <div className="px-4 md:px-6 mb-9">
            <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2">
              {/* <div className="my-2 px-2 w-1/2 overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4">
                <div className="py-3 md:py-5 px-4 md:px-6 rounded border bg-white shadow-sm hover:shadow">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm mb-2">Total savers</p>
                      <h2 className="text-2xl text-primary font-semibold">
                        40,000.00
                      </h2>
                      <p className="text-xs hidden md:block md:mt-2">
                        <span style={{ color: "#188F32" }}>+100%</span> this
                        week
                      </p>
                    </div>
                    <div className="hidden md:flex items-center">
                      <div
                        className="rounded-full w-12 h-12 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(200, 236, 252, 0.5)" }}
                      >
                        <img src={deposits} alt="..." className="w-5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="my-2 px-2 w-1/2 overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
                <div className="py-3 md:py-5 px-4 md:px-6 rounded border bg-white shadow-sm hover:shadow">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm mb-2">Total savings</p>
                      <h2 className="text-2xl text-primary font-semibold">
                        {formatMoney(totalSavings)}
                      </h2>
                      {/* <p className="text-xs hidden md:block md:mt-2">
                        <span style={{ color: "#188F32" }}>+100%</span> this
                        week
                      </p> */}
                    </div>
                    <div className="hidden md:flex items-center">
                      <div
                        className="rounded-full w-12 h-12 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(222, 255, 229, 0.5)" }}
                      >
                        <img src={savings} alt="..." className="w-5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 px-2 w-1/2 overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
                <div className="py-3 md:py-5 px-4 md:px-6 rounded border bg-white shadow-sm hover:shadow">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm mb-2">Total payout</p>
                      <h2 className="text-2xl text-primary font-semibold">
                        {formatMoney(totalPayouts)}
                      </h2>
                      {/* <p className="text-xs hidden md:block md:mt-2">
                        <span style={{ color: "#188F32" }}>+0%</span> this week
                      </p> */}
                    </div>
                    <div className="hidden md:flex items-center">
                      <div
                        className="rounded-full w-12 h-12 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(251, 225, 238, 0.5)" }}
                      >
                        <img src={withdraw} alt="..." className="w-5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 px-2 w-1/2 overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
                <div className="py-3 md:py-5 px-4 md:px-6 rounded border bg-white shadow-sm hover:shadow">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm mb-2">Total Earnings</p>
                      <h2 className="text-2xl text-primary font-semibold">
                        {formatMoney(earnings)}
                      </h2>
                      {/* <p className="text-xs hidden md:block md:mt-2">
                        <span style={{ color: "#188F32" }}>+100%</span> this
                        week
                      </p> */}
                    </div>
                    <div className="hidden md:flex items-center">
                      <div
                        className="rounded-full w-12 h-12 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(241, 238, 249, 0.5)" }}
                      >
                        <img src={money} alt="..." className="w-5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* <!-- Left: Title --> */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Transactions ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                <FilterButton />
                {/* Datepicker built with flatpickr */}
                <Datepicker />
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full -m-24 pb-6 md:pb-0">
          {/* table */}
          <div className="w-full mb-6 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Reference number
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Amount
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Purpose
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Transaction type
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Date & Time
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {transactions.map((transaction) => (
                        <tr
                          key={transaction.reference}
                          onClick={() => handleRowClick(transaction)}
                          className="hover:bg-light cursor-pointer"
                        >
                          <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm font-normal text-left">
                            {transaction.reference}
                          </th>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            ₦ {transaction.amount}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            {transaction.purpose}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            {transaction.txn_type}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            {new Date(transaction.createdAt).toLocaleString()}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            <span
                              className="px-2 py-1 rounded-full"
                              style={{
                                backgroundColor: `${
                                  transaction.status === "Success"
                                    ? "#DEFFE5"
                                    : "#FDEDED"
                                }`,
                                color: `${
                                  transaction.status === "Success"
                                    ? "#188F32"
                                    : "#EA3433"
                                }`,
                              }}
                            >
                              {transaction.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pb-12">
            <Paginator
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              disabledPrevious={page === 1}
              disabledNext={page === pagesCount}
              pagesCount={pagesCount}
              setPage={setPage}
              page={page}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

import React, { Fragment, useState } from "react";
import axios from "../../../axios";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SpinnerIcon from "components/Icons/Spinner";

const AddSavingsGroupModal = ({ show, setShow, addGroup, toggleAdd }) => {
  const formSchema = Yup.object().shape({
    groupName: Yup.string().required("Group name is required").trim(""),
    description: Yup.string().trim(""),
    slotsNumber: Yup.number()
      .required("Number of slots is required")
      .min(3, "Number of slots must be at least 3")
      .max(12, "Number of slots can't be greater than 12"),
    amount: Yup.number()
      .required("Savings amount is required")
      .min(1000, "Minimum savings is 1000")
      .max(500_000, "Maximum savings is 500_000"),
    frequency: Yup.string().required("Draw frequency is required"),
    charges: Yup.number()
      .required("Fee changes is required")
      .min(250, "Cannot charge less than NGN 250"),
  });

  const [loading, setLoading] = useState(false);

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  const handleAdd = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post("/savings-group/create", values);
      toast.success(response.data.message);
      setLoading(false);
      addGroup(response.data.data);
      toggleAdd();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(errors);
  return (
    <>
      {show ? (
        <Fragment>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-h-[80vh] max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid border-borderbg rounded-t">
                  <h3 className="text-base font-medium text-blackcolor leading-5">
                    Add New Savings Group
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-y-scroll h-full md:h-[320px]">
                  <form id="addressForm" onSubmit={handleSubmit(handleAdd)}>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="groupName"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Group name
                      </label>
                      <input
                        id="groupName"
                        type="text"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Group name"
                        style={{ transition: "all .15s ease" }}
                        {...register("groupName", { required: true })}
                      />
                      {errors?.groupName ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.groupName?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="description"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Group description (optional)"
                        style={{ transition: "all .15s ease" }}
                        {...register("description", { required: true })}
                        rows={2}
                      />
                      {errors?.description ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.description?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="amount"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Savings Amount (Min. NGN 1000.00)
                      </label>
                      <input
                        id="amount"
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Savings Amount"
                        style={{ transition: "all .15s ease" }}
                        {...register("amount", { required: true })}
                        defaultValue={1000}
                        min={1000}
                      />
                      {errors?.slotsNumber ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.slotsNumber?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="slotsNumber"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Number of slots
                      </label>
                      <input
                        id="slotsNumber"
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="No of slots"
                        style={{ transition: "all .15s ease" }}
                        {...register("slotsNumber", { required: true })}
                        defaultValue={3}
                        min={3}
                      />
                      {errors?.slotsNumber ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.slotsNumber?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="frequency"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Draw Frequency
                      </label>
                      <select
                        className="form-select block w-full px-3 rounded border text-sm border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
                        id="frequency"
                        {...register("frequency", { required: true })}
                      >
                        <option />

                        <option className="text-sm" value="DAILY">
                          Daily
                        </option>
                        <option className="text-sm" value="WEEKLY">
                          Weekly
                        </option>
                        <option className="text-sm" value="MONTHLY">
                          Monthly
                        </option>
                        <option className="text-sm" value="CUSTOM">
                          Custom (In Days)
                        </option>
                      </select>
                      {errors?.frequency ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.frequency?.message}
                        </p>
                      ) : null}
                      {watch("frequency") === "CUSTOM" && (
                        <>
                          <input
                            type="number"
                            className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full mt-4"
                            placeholder="Custom Frequency (In Days)"
                            style={{ transition: "all .15s ease" }}
                            {...register("customDays", { required: true })}
                            min={3}
                            max={31}
                            required
                          />
                          {errors?.customDays ? (
                            <p className="text-left text-red-600 text-xs mt-1">
                              This field is required
                            </p>
                          ) : null}
                        </>
                      )}
                    </div>
                    <div className="relative w-full">
                      <label
                        htmlFor="charges"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Admin charges (Min. NGN 250.00)
                      </label>
                      <input
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Min. NGN 250.00"
                        style={{ transition: "all .15s ease" }}
                        id="charges"
                        {...register("charges", { required: true })}
                        defaultValue={0}
                      />
                      {errors?.charges ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.charges?.message}
                        </p>
                      ) : null}
                    </div>
                    {/* <div className="relative hidden">
                      <div className="flex items-center">
                        <input
                          id="add-me"
                          name="add-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                          {...register("addAdmin")}
                        />
                        <label
                          htmlFor="add-me"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Add user as a member
                        </label>
                      </div>
                      {errors?.addAdmin ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.addAdmin?.message}
                        </p>
                      ) : null}
                    </div> */}
                  </form>
                </div>
                {/*footer*/}
                <div className="flex p-6 border-t border-solid rounded-b justify-center">
                  <button
                    className={
                      "bg-primary text-white capitalize text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                      (loading ? "opacity-50 cursor-not-allowed" : "")
                    }
                    form="addressForm"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <SpinnerIcon /> : "Create Savings Group"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
      ) : null}
    </>
  );
};

export default AddSavingsGroupModal;

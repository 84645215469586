import { LogoutIcon, UserGroupIcon } from "@heroicons/react/outline";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import sidebarMenu from "../../config/menu/sidebar-static";
import Menu from "./menu";
import { useMe } from "providers/useMe";
import logo from "assets/logo/logo.svg";
import { Link } from "react-router-dom";
import {
  SwitchVerticalIcon,
  UsersIcon,
  CogIcon,
  ChartPieIcon,
} from "@heroicons/react/outline";

const staticMenu = sidebarMenu();

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [showMenu, setMenuVisibility] = useState(false);
  const { user, loading, logoutUser } = useMe();

  const renderVerificationMenu = () => {
    return (
      user && (
        <Menu
          data={{
            name: "Getting started",
            menuItems: [
              {
                name: "Request verification",
                path: "/verification",
                icon: <CogIcon />,
              },
            ],
          }}
          isLoading={loading}
          showMenu={!user.isAgentApproved || loading}
        />
      )
    );
  };

  const renderMenu = () => {
    return staticMenu.map((item, index) => (
      <Menu
        key={index}
        data={item}
        showMenu={loading || user.isAgentApproved}
        isLoading={loading}
      />
    ));
  };

  const toggleMenu = () => setMenuVisibility(!showMenu);

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const logOutHandler = async () => {
    window.confirm("Are you sure you want to logout?");
    await logoutUser();
    navigate("/");
  };

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`border-r border flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-white p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="#" className="block uppercase text-dark">
            <img src={logo} alt="adashi.ng" className="w-28" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block"></span>
            </h3>

            <ul className="mt-3">
              {renderVerificationMenu()}
              {renderMenu()}
              <li className="items-center rounded-sm mb-0.5 last:mb-0">
                <button
                  className="px-3 py-2 flex hover:opacity-80 items-center text-dark w-full"
                  onClick={logOutHandler}
                >
                  <div className="w-6 h-6 shrink-0">
                    <LogoutIcon />
                  </div>
                  <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    Logout
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {user.isAgentApproved && (
        <div className="sticky z-40 flex flex-col space-y-5 text-gray-900 bg-white bg-white lg:overflow-y-auto lg:w-1/4 lg:h-screen overscroll-contain md:hidden">
          <section
            id="bottom-navigation"
            className="lg:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
          >
            <div className="flex justify-between text-dark">
              <Link
                to="/dashboard"
                className={
                  "w-full text-dark focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 " +
                  (location.pathname.includes("/dashboard") && "text-primary")
                }
              >
                <ChartPieIcon className="w-6 h-6 inline-block mb-1" />

                <span className="font-semibold block text-xs">Dashboard</span>
              </Link>

              <Link
                to="/transactions"
                className={
                  "w-full text-dark focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 " +
                  (location.pathname.includes("/transactions") &&
                    "text-primary")
                }
              >
                <SwitchVerticalIcon className="w-6 h-6 inline-block mb-1" />
                <span className=" font-semibold block text-xs">
                  Transactions
                </span>
              </Link>

              <Link
                to="/savers"
                className={
                  "w-full text-dark focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 " +
                  (location.pathname.includes("/savers") && "text-primary")
                }
              >
                <UsersIcon className="w-6 h-6 inline-block mb-1" />
                <span className=" font-semibold block text-xs">Savers</span>
              </Link>

              <Link
                to="/groups"
                className={
                  "w-full text-dark focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 " +
                  (location.pathname.includes("/groups") && "text-primary")
                }
              >
                <UserGroupIcon className="w-6 h-6 inline-block mb-1" />
                <span className=" font-semibold block text-xs">Groups</span>
              </Link>

              <Link
                to="/settings"
                className={
                  "w-full text-dark focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 " +
                  (location.pathname.includes("/settings") && "text-primary")
                }
              >
                <CogIcon className="w-6 h-6 inline-block mb-1" />
                <span className=" font-semibold block text-xs">Settings</span>
              </Link>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Sidebar;

import Header from "components/Header";
import SetTrxnPin from "components/Modals/SetTrxnPin";
import Sidebar from "components/Sidebar/index";
import { SearchFilterContextProvider } from "context/SearchFilterContext";
import { useMe } from "providers/useMe";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setModalVisibility] = useState(false);

  const { user } = useMe();

  const toggleModal = () => setModalVisibility(!showModal);

  useEffect(() => {
    if (user) {
      if (user.email_verified_at === null) {
        navigate("/auth/verify-email");
      } else {
        if (!user.isAgentApproved) {
          navigate("/verification");
        }
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      if (user.isAgentApproved && !user.txn_pin.isTrxnPinSet) {
        setModalVisibility(true);
      }
    }
  }, [user]);

  return (
    <SearchFilterContextProvider>
      <React.Fragment>
        <SetTrxnPin show={showModal} toggleModal={toggleModal} />
        <main className="relative flex flex-col w-screen h-screen space-x-0 text-gray-800 sm:flex-row bg-light min-w-full">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <Toaster position="top-center" toastOptions={{ duration: 10000 }} />
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="mt-5">{children}</div>
          </div>
        </main>
      </React.Fragment>
    </SearchFilterContextProvider>
  );
};

export default DashboardLayout;

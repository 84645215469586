import { useUser } from "hooks/data/useUser";
import React, { useEffect, useState } from "react";
import AddBankAccount from "../Modals/AddBankAccount";

export default function BankAccount() {
  const { isLoading, user } = useUser();
  const [bankAccounts, setBankAccounts] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    if (user) {
      setBankAccounts(user.bankAccounts);
    }
  }, [user]);

  return (
    <div className={`px-4 sm:px-6 lg:px-8 mx-auto w-full block`}>
      <div className="w-full mb-12 xl:mb-0">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-lg">Bank Account(s)</h3>
              </div>
              <div className="relative w-full max-w-full flex-grow flex-1 text-right">
                <button
                  className="bg-primary text-white active:bg-indigo-600 text-sm font-medium px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={toggleModal}
                >
                  Add Bank
                </button>
                {showModal && (
                  <AddBankAccount
                    setBankAccounts={(bank) => setBankAccounts(bank)}
                    bankAccounts={bankAccounts}
                    showModal={showModal}
                    toggleModal={toggleModal}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Bank name
                  </th>
                  <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Account Number
                  </th>
                  <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Account Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  user &&
                  bankAccounts.map((bank) => (
                    <tr key={bank.accountNumber}>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm flex justify-start">
                        <div className="flex items-center justify-center">
                          <img
                            src={bank.bankLogo}
                            alt="..."
                            className="w-10 h-10 border shadow-sm"
                          />
                          <div className="ml-2 hidden sm:flex">
                            {bank.bankName}
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                        {bank.accountNumber}
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                        {bank.accountName}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from "../axios";

export function addBankAccount(payload) {
  return axios.post("/user/add-bank", payload).then((res) => res.data);
}

export async function changePassword(payload) {
  return axios.post("/auth/change-password", payload).then((res) => res.data);
}

export async function changeTrxnPin(payload) {
  return axios.post("/agent/change-pin", payload).then((res) => res.data);
}

export async function getVerificationStatus() {
  return await axios
    .get("/agent/approval-request/status")
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export async function fetchUserDetails() {
  return await axios.get("/user/me");
}

export function setTransactionPin(pin) {
  return axios.post("/auth/set-pin", { pin }).then((res) => res.data);
}

export function fetchAllBanks() {
  return axios.get("/wallet/all-nigerian-banks").then((res) => res.data);
}

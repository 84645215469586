import { ClockIcon } from "@heroicons/react/outline";
import Deposit from "components/Modals/Deposit";
import Withdraw from "components/Modals/Withdraw";
import React from "react";
import { Link } from "react-router-dom";
import { formatMoney } from "../../helpers/index";

function BalanceCard({ balance, loading }) {
  return (
    <div className="flex overflow-x-scroll hide-scroll-bar">
      <div className="flex flex-nowrap space-x-4 md:space-x-6 w-full">
        <div className="bg-primary rounded px-8 py-6 md:w-3/5 w-full min-w-max">
          <h1 className="font-light text-sm text-white mb-1">
            Main Wallet Balance
          </h1>

          <div className="flex justify-between md:items-center flex-col md:flex-row">
            <p className="text-white spa text-3.5xl font-semibold mb-0">
              ₦{loading ? "---" : formatMoney(balance?.balance ?? 0)}
            </p>
          </div>
          <div className="mt-4 flex items-center md:space-x-6 space-x-4">
            <Deposit />
            <Withdraw />
          </div>
        </div>
        <div className="bg-primary rounded px-8 py-6 md:w-2/5 w-full md:min-w-[unset] min-w-[80vw]">
          <h1 className="font-light text-sm text-white mb-1">
            Commissions Balance
          </h1>

          <div className="flex justify-between items-start flex-col w-auto">
            <p className="text-white spa text-3.5xl font-semibold mb-0">
              ₦{loading ? "---" : formatMoney(balance?.earnings ?? 0)}
            </p>

            <div className="mt-4">
              <Link
                to="/commissions"
                className="py-3 px-4 font-medium rounded-lg bg-white text-primary flex items-center justify-start"
              >
                <ClockIcon className="w-6 h-6 hidden md:flex" />
                <span className="md:ml-2">View commission</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceCard;

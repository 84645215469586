import React, { Fragment, useState } from "react";
import axios from "../../axios";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SpinnerIcon from "components/Icons/Spinner";

const UpdateSaverModal = ({ saver, savers, setSavers, show, setShow }) => {
  const formSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required").trim(""),
    lastName: Yup.string().required("Last name is required").trim(""),
    phone: Yup.string(),
    occupation: Yup.string().required("Occupation is required"),
    address: Yup.string().required("Street address is required"),
    city: Yup.string(),
    state: Yup.string().required("State is required"),
    savingsAmount: Yup.number()
      .required("Savings amount is required")
      .min(200, "Cannot save less than NGN 200"),
  });

  const [loading, setLoading] = useState(false);

  const formOptions = {
    resolver: yupResolver(formSchema),
    defaultValues: { phone: `0${saver?.phone}` },
  };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const handleUpdate = async (data) => {
    try {
      if (!saver._id) return;
      setLoading(true);
      setLoading(true);
      const response = await axios.patch(`/saver/saver/${saver._id}`, data);

      const updatedSaver = response.data.data;

      const saversList = [...savers];

      const foundIndex = savers.findIndex((s) => s._id === saver._id);

      saversList[foundIndex].savingsAmount = updatedSaver.savingsAmount;
      setSavers(saversList);
      toast.success("Saver updated successful!");
      setLoading(false);
      setShow(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response?.data?.message);
    }
  };

  return (
    <>
      {show ? (
        <Fragment>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-5/6 my-6 mx-auto max-h-[80vh] max-w-lg md:w-5/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-4 px-6 border-b border-solid border-borderbg rounded-t">
                  <h3 className="text-base font-medium text-blackcolor leading-5">
                    Update Saver
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-20 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-y-scroll h-[300px]">
                  <form id="addressForm" onSubmit={handleSubmit(handleUpdate)}>
                    <div className="flex flex-col md:flex-row justify-between">
                      <div className="relative w-full md:w-12/25 mb-3">
                        <label
                          htmlFor="firstName"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          First name
                        </label>
                        <input
                          id="firstName"
                          type="text"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="First name"
                          style={{ transition: "all .15s ease" }}
                          {...register("firstName", { required: true })}
                          defaultValue={saver?.firstName}
                        />
                        {errors?.firstName ? (
                          <p className="text-left text-red-600 text-xs mt-1">
                            {errors.firstName?.message}
                          </p>
                        ) : null}
                      </div>
                      <div className="relative w-full md:w-12/25 mb-3">
                        <label
                          htmlFor="lastName"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          Last name
                        </label>
                        <input
                          id="lastName"
                          type="text"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Last name"
                          style={{ transition: "all .15s ease" }}
                          {...register("lastName", { required: true })}
                          defaultValue={saver?.lastName}
                        />
                        {errors?.lastName ? (
                          <p className="text-left text-red-600 text-xs mt-1">
                            {errors.lastName?.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="phone"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Phone number
                      </label>
                      <input
                        id="phone"
                        type="tel"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Phone number"
                        style={{ transition: "all .15s ease" }}
                        {...register("phone", { disabled: true })}
                      />
                      {errors?.phone ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.phone?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="occupation"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Occupation
                      </label>
                      <input
                        type="text"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Occupation"
                        style={{ transition: "all .15s ease" }}
                        id="occupation"
                        {...register("occupation", { required: true })}
                        defaultValue={saver?.occupation}
                      />
                      {errors?.occupation ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.occupation?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="address"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Street address
                      </label>
                      <input
                        type="text"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Street address"
                        style={{ transition: "all .15s ease" }}
                        id="address"
                        {...register("address", { required: true })}
                        defaultValue={saver?.address}
                      />
                      {errors?.address ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.address?.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="flex flex-col md:flex-row justify-between mb-3">
                      <div className="relative w-full md:w-12/25 mb-3">
                        <label
                          htmlFor="city"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          City/Area
                        </label>
                        <input
                          type="text"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="City/Area"
                          style={{ transition: "all .15s ease" }}
                          id="city"
                          {...register("city", { required: false })}
                          defaultValue={saver?.city}
                        />
                        {errors?.city ? (
                          <p className="text-left text-red-600 text-xs mt-1">
                            {errors.city?.message}
                          </p>
                        ) : null}
                      </div>
                      <div className="relative w-full md:w-12/25 mb-3">
                        <label
                          htmlFor="state"
                          className="text-sm font-medium text-gray-900 block mb-2 text-left"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="State"
                          style={{ transition: "all .15s ease" }}
                          id="state"
                          {...register("state", { required: true })}
                          defaultValue={saver?.state}
                        />
                        {errors?.state ? (
                          <p className="text-left text-red-600 text-xs mt-1">
                            {errors.state?.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="relative w-full">
                      <label
                        htmlFor="savingsAmount"
                        className="text-sm font-medium text-gray-900 block mb-2 text-left"
                      >
                        Savings amount (Min. NGN 200.00)
                      </label>
                      <input
                        type="number"
                        className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Min. NGN 200.00"
                        style={{ transition: "all .15s ease" }}
                        id="savingsAmount"
                        {...register("savingsAmount", { required: true })}
                        defaultValue={saver?.savingsAmount ?? 0}
                      />
                      {errors?.savingsAmount ? (
                        <p className="text-left text-red-600 text-xs mt-1">
                          {errors.savingsAmount?.message}
                        </p>
                      ) : null}
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex p-6 border-t border-solid rounded-b justify-center">
                  <button
                    className={
                      "bg-primary text-white capitalize text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                      (loading ? "opacity-50 cursor-not-allowed" : "")
                    }
                    form="addressForm"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <SpinnerIcon /> : "Update Saver"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
      ) : null}
    </>
  );
};

export default UpdateSaverModal;

import React, { useState, useContext } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthContext from "context/AuthContext";

import toast from "react-hot-toast";

import axios from "../../axios";
import tracker, { SIGN_UP_COMPLETE } from "../../utils/tracking";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import SpinnerIcon from "../../components/Icons/Spinner";
import useTitle from "hooks/useTitle";

const signupFormSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone is required")
    .min(10, "Phone is invalid")
    .max(11, "Phone is invalid"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at 6 char long"),
  confirmPassword: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});

export default function Signup() {
  useTitle("Adashi - Signup");

  let navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const formOptions = { resolver: yupResolver(signupFormSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTos, setAgreeTos] = useState(false);

  const toggleShowPasswd = () => setShowPassword(!showPassword);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  const handleSignup = async (values) => {
    const { firstName, lastName, email, phoneNumber, password } = values;

    const newUser = { firstName, lastName, email, phoneNumber, password };

    try {
      setLoading(true);
      await axios.post("/auth/signup", newUser);
      setLoading(false);
      toast.success("Signup successful");
      tracker.track(SIGN_UP_COMPLETE);
      navigate("/auth/verify-email");
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response) toast.error(error.response?.data?.message);
    }
  };

  return (
    <div className="bg-light min-h-screen grid place-content-center py-10 md:py-20">
      <div className="text-center w-screen md:w-auto">
        <h1 className="text-3xl text-primary mb-6">Adashi.ng</h1>
        <p className="text-sm text-dark">
          Let's get you started with your Adashi.ng account
        </p>

        <form
          className="signup-box max-w-xl md:w-136 mx-auto mt-5 px-4 md:px-0"
          onSubmit={handleSubmit(handleSignup)}
        >
          <div className="flex flex-col md:flex-row justify-between">
            <div className="relative w-full md:w-[48%] mb-3">
              <label
                htmlFor="firstName"
                className="text-sm font-medium block mb-2 text-left opacity-80"
              >
                First name
              </label>
              <input
                type="text"
                id="firstName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
                placeholder="First name"
                style={{ transition: "all .15s ease" }}
                {...register("firstName", { required: true })}
              />
              {errors.firstName && (
                <p className="text-left text-red-600 text-xs mt-1">
                  {errors.firstName?.message}
                </p>
              )}
            </div>
            <div className="relative w-full md:w-[48%] mb-3">
              <label
                htmlFor="lastName"
                className="text-sm font-medium block mb-2 text-left opacity-80"
              >
                Last name
              </label>
              <input
                type="text"
                id="lastName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
                placeholder="Last name"
                style={{ transition: "all .15s ease" }}
                {...register("lastName", { required: true })}
              />
              {errors.lastName && (
                <p className="text-left text-red-600 text-xs mt-1">
                  {errors.lastName?.message}
                </p>
              )}
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="phoneNumber"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Phone number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
              placeholder="08123456789"
              {...register("phoneNumber", { required: true, minLength: 10 })}
            />
            {errors.phoneNumber && (
              <p className="text-left text-red-600 text-xs mt-1">
                {errors.phoneNumber?.message}
              </p>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Email address
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
              placeholder="example@email.com"
              {...register("email", { required: true })}
            />
            {errors.email?.type === "required" && (
              <p className="text-left text-red-600 text-xs mt-1">
                Email address is required
              </p>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
                placeholder="At least 6 characters"
                {...register("password", { required: true, minLength: 6 })}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={toggleShowPasswd}
              >
                {showPassword ? (
                  <EyeOffIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>

            {errors?.password && (
              <p className="text-left text-red-600 text-xs mt-1">
                {errors?.password?.message}
              </p>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="confirmPassword"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Confirm Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full px-3 py-1 focus:outline-none focus:ring h-12"
                placeholder="At least 6 characters"
                {...register("confirmPassword", { required: true })}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={toggleShowPasswd}
              >
                {showPassword ? (
                  <EyeOffIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>

            {errors?.confirmPassword && (
              <p className="text-left text-red-600 text-xs mt-1">
                {errors.confirmPassword?.message}
              </p>
            )}
          </div>

          <div className="flex items-center mb-4">
            <input
              id="agreeTos"
              name="agreeTos"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              onChange={() => setAgreeTos(!agreeTos)}
            />
            <label
              htmlFor="agreeTos"
              className="ml-2 block text-xs text-gray-900"
            >
              I have read, understood and I agree to Adashi’s
              <Link className="mx-1" to="/privacy-policy">
                <span className="text-primary">Privacy Policy,</span>
              </Link>
              and
              <Link className="ml-1" to="/terms-condition">
                <span className="text-primary">Terms and conditions</span>
              </Link>
              .
            </label>
          </div>

          <button
            type="submit"
            className={
              "py-1 border-primary border w-full text-center bg-primary text-white text-sm h-12 flex justify-center items-center " +
              ((loading || !agreeTos) && "bg-opacity-50 cursor-not-allowed")
            }
            disabled={loading || !agreeTos}
          >
            {loading ? <SpinnerIcon /> : <span>Create your account</span>}
          </button>

          <p className="text-dark opacity-80 mt-4 text-sm">
            Already have an account
            <Link to="/auth/login" className="text-primary ml-2 opacity-100">
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

import { useQuery } from "react-query";
import axios from "../../../axios";

const fetchGroupDetails = (groupId) => {
  return axios.get("/savings-group/details/" + groupId).then((res) => res.data);
};

export const useGroup = (groupId) => {
  const { data, isLoading, refetch } = useQuery(`group-${groupId}`, () =>
    fetchGroupDetails(groupId)
  );
  return { group: data?.data, isLoading, refetch };
};

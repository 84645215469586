import { ArrowDownIcon } from "@heroicons/react/outline";
import Datepicker from "components/Buttons/Datepicker";
import FilterButton from "components/Buttons/FilterButton";
import CashoutCommissionModal from "components/Modals/CashoutCommission";
import { DashboardLayout } from "layouts";
import React, { useEffect, useState } from "react";
import axios from "../../axios";

function getAgentCommissions() {
  return new Promise((resolve, reject) => {
    axios
      .get("/transactions/commissions")
      .then((res) => resolve(res?.data?.data))
      .catch((err) => reject(err));
  });
}

const AgentCommissions = () => {
  const [agentCommissions, setAgentCommissions] = useState([]);

  useEffect(() => {
    getAgentCommissions().then(setAgentCommissions).catch(console.log);
  }, []);

  return (
    <DashboardLayout>
      <div className="">
        <div className="relative pb-28">
          <div className="px-4 md:px-6 mx-auto w-full flex mb-10">
            <p className="text-dark text-xl font-semibold md:hidden">
              Commissions
            </p>
          </div>
          <div className="px-4 sm:px-6 lg:px-8 mb-8">
            <div className="sm:flex sm:justify-between sm:items-center">
              {/* <!-- Left: Title --> */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Commissions ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 align-middle">
                {/* Filter button */}
                <FilterButton />
                {/* Datepicker built with flatpickr */}
                <Datepicker />
                <div className="hidden md:flex">
                  <CashoutCommissionModal />
                </div>
              </div>
            </div>
            <div className="md:hidden flex">
              <CashoutCommissionModal />
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8 mx-auto w-full -m-24">
          {/* table */}
          <div className="w-full mb-6 xl:mb-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border border-t-0 rounded">
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Reference number
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Amount
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Purpose
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Transaction type
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Date & Time
                      </th>
                      <th className="px-4 text-gray-900 align-middle border border-solid py-3 text-base border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {agentCommissions.reverse().map((transaction) => (
                        <tr
                          key={transaction.reference}
                          className="hover:bg-light cursor-pointer"
                        >
                          <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm font-normal text-left">
                            {transaction.reference}
                          </th>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            ₦ {transaction.amount}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            {transaction.purpose}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            {transaction.txn_type}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            {new Date(transaction.createdAt).toLocaleString()}
                          </td>
                          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-sm">
                            <span
                              className="px-2 py-1 rounded-full"
                              style={{
                                backgroundColor: `${
                                  transaction.status === "Success"
                                    ? "#DEFFE5"
                                    : "#FDEDED"
                                }`,
                                color: `${
                                  transaction.status === "Success"
                                    ? "#188F32"
                                    : "#EA3433"
                                }`,
                              }}
                            >
                              {transaction.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AgentCommissions;

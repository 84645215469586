import { useNigBanks } from "hooks/data/useNigBanks";
import { useDebounce } from "libs/Hooks";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../../axios";

const BankResolveCard = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const [bankDetails, setBankDetails] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { data, banksLoading } = useNigBanks();

  const banks = banksLoading ? [] : data?.banks;

  const BankSelect = React.forwardRef(
    ({ onChange, onBlur, name, label, defaultValue }, ref) => (
      <>
        <label
          htmlFor="bankName"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          {label}
        </label>
        <select
          className="border border-secondary_sky_base px-3 py-2 placeholder-grey_80 text-grey_40 bg-white focus:outline-none focus:ring w-full rounded-lg"
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
        >
          <option />
          {banks?.map((bank) => (
            <option key={bank.bankCode} value={JSON.stringify(bank)}>
              {bank.bankName}
            </option>
          ))}
          <style jsx>{`
            select {
              padding: 1rem;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23090A0A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ")
                97% / 3% no-repeat;
            }
          `}</style>
        </select>
      </>
    )
  );
  BankSelect.displayName = "BankSelect";

  useDebounce(() => resolveAccount(), 1000, [watch("accountNumber")]);

  const resolveAccount = async () => {
    if (watch("accountNumber").length === 10) {
      try {
        setLoading(true);
        const response = await axios.post("/wallet/resolve-bank", {
          bankCode: JSON.parse(watch("bank")).bankCode,
          accountNumber: watch("accountNumber"),
        });

        document
          .getElementById("accountNumber")
          .classList.remove("border-red-500");
        document
          .getElementById("accountNumber")
          .classList.add("border-green-500");
        setBankDetails(response.data.data.details);

        setLoading(false);
        setDisable(false);
      } catch (error) {
        if (document.getElementById("accountId")) {
          document
            .getElementById("accountNumber")
            .classList.remove("border-green-500");
          document
            .getElementById("accountNumber")
            .classList.add("border-red-500");
        }

        setLoading(false);
        setDisable(true);
      }
    }
  };
  return (
    <div>
      {" "}
      <div className="relative md:w-full">
        <BankSelect
          label="Bank Name"
          {...register("bank", { required: true })}
        />
        {errors.bank?.type === "required" && (
          <p className="text-left text-red-600 text-xs mt-1">
            Bank name is required
          </p>
        )}
      </div>
      <div className="relative w-full my-3">
        <label
          htmlFor="accountNumber"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          Account Number
        </label>
        <input
          type="number"
          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
          placeholder="Account Number"
          style={{ transition: "all .15s ease" }}
          name="accountNumber"
          id="accountNumber"
          {...register("accountNumber", { required: true })}
        />
      </div>
      <div className="relative w-full mb-3">
        <label
          htmlFor="accountName"
          className="text-sm font-medium text-gray-900 block mb-2 text-left"
        >
          Account Name
        </label>
        <input
          type="text"
          className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
          placeholder="Account Name"
          style={{ transition: "all .15s ease" }}
          name="accountName"
          value={bankDetails?.BeneficiaryName}
          readOnly
          required
        />
      </div>
    </div>
  );
};

export default BankResolveCard;

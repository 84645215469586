import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import OtpForm from "react-otp-ui";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { changeTrxnPin } from "../../apicore";
import { XIcon } from "@heroicons/react/outline";

const ChangeTrxnPin = ({ show, toggle }) => {
  const [oldPin, setOldPin] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [disableConfirm, setDisableConfirm] = useState(true);

  const handleCurrPinChange = (event) => {
    if (event.otpValue.length === 4) {
      setOldPin(event.otpValue);
    }
  };

  const handleNewPinChange = (event) => {
    if (event.otpValue.length === 4) {
      setPin(event.otpValue);
    }
  };
  const handleConfirmNewPinChange = (event) => {
    if (event.otpValue.length === 4) {
      setConfirmPin(event.otpValue);
    }
  };

  useEffect(() => {
    const inputEl = document.querySelectorAll(".changing-pin-input");

    if (pin.length === 4 && confirmPin.length === 4) {
      if (pin !== confirmPin) {
        toast.error("Pin and confirm pin mismatch", { duration: 700 });
        for (let i = 0; i < inputEl.length; i++) {
          inputEl[i].classList.add("!border-red-500");
        }
      } else {
        setDisableConfirm(false);
      }
    }

    return () => {
      for (let i = 0; i < inputEl.length; i++) {
        inputEl[i].classList.remove("!border-red-500");
      }
    };
  }, [pin, confirmPin]);

  const mutation = useMutation(changeTrxnPin, {
    onSuccess: (res) => {
      toast.success(res.message);
      setOldPin("");
      setPin("");
      setConfirmPin("");
      toggle();
    },
    onError: (error) => {
      console.log(error);
      toast.error(error.response.data.message);
    },
  });

  const { mutate, isLoading } = mutation;

  const changePinHandler = (event) => {
    event.preventDefault();
    mutate({
      current_pin: oldPin,
      new_pin: pin,
    });
  };

  return (
    <Transition appear as={Fragment} show={show}>
      <Dialog
        className="fixed inset-0 z-50 overflow-y-auto text-gray-800"
        onClose={toggle}
      >
        <div className="flex items-center justify-center h-screen p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>
          <span aria-hidden="true" className="inline-block align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block p-10 my-10 space-y-5 overflow-hidden text-left align-middle transition-all transform bg-white rounded shadow-xl">
              <Dialog.Title
                as="h2"
                className="text-lg font-medium leading-5 text-center"
              >
                Change Transaction Pin
              </Dialog.Title>
              <button
                className="absolute top-0 outline-none right-5"
                onClick={toggle}
              >
                <XIcon className="w-6 h-6" />
              </button>
              <form onSubmit={changePinHandler}>
                <div className="mt-6">
                  <div className="relative mb-4">
                    <span className="text-sm font-medium text-gray-900 block mb-2 text-left">
                      Enter Pin
                    </span>
                    <div className="flex justify-center mt-5 mb-2">
                      <OtpForm
                        onChange={handleCurrPinChange}
                        className="pin-input"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <span className="text-sm font-medium text-gray-900 block mb-2 text-left">
                      Enter your new pin
                    </span>
                    <div className="flex justify-center mt-5 mb-2">
                      <OtpForm
                        onChange={handleNewPinChange}
                        className="changing-pin-input"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <span className="text-sm font-medium text-gray-900 block mb-2 text-left">
                      Confirm your new pin
                    </span>
                    <div className="flex justify-center mt-5 mb-2">
                      <OtpForm
                        onChange={handleConfirmNewPinChange}
                        className="changing-pin-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-center">
                  <button
                    className={
                      "bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                      ((disableConfirm || isLoading || pin !== confirmPin) &&
                        "bg-opacity-30")
                    }
                    disabled={disableConfirm || isLoading || pin !== confirmPin}
                    type="submit"
                  >
                    {isLoading ? "..." : "Change Pin"}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ChangeTrxnPin;

import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    confirmPassword: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token") || "";
  const navigate = useNavigate();

  const handleReset = async (data) => {
    try {
      setLoading(true);
      const { password } = data;
      await axios.post("/auth/reset-password", { password, token });
      toast.success("Your password has been reset");
      setLoading(false);
      reset({ password: "", confirmPassword: "" });
      navigate("/auth/login");
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response) {
        toast.error(err.response.data.message || err.response.data);
      }
    }
  };

  return (
    <div className="bg-light min-h-screen grid place-content-center">
      <div className="text-center w-screen md:w-auto">
        <h1 className="text-3xl text-primary mb-7">Adashi.ng</h1>

        <h2 className="text-gray-900 mb-4 text-3xl md:text-4xl font-semibold leading-9">
          Reset Password
        </h2>
        <p className="text-sm text-dark">
          Enter your new password. Make sure it is unique to you.
        </p>

        <form
          onSubmit={handleSubmit(handleReset)}
          className="login-box max-w-xl md:w-[34rem] mx-auto mt-5 px-4 md:px-0"
        >
          <div className="mb-3">
            <label
              htmlFor="password"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full py-1 px-3 focus:outline-none focus:ring h-12"
              {...register("password", { required: true, minLength: 6 })}
            />
            {errors?.password ? (
              <p className="text-left text-red-600 text-xs mt-1">
                Password is required
              </p>
            ) : null}
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="text-sm font-medium block mb-2 text-left opacity-80"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-200 focus:border-blue-400 block w-full py-1 px-3 focus:outline-none focus:ring h-12"
              {...register("confirmPassword", { required: true, minLength: 6 })}
            />
            {errors?.confirmPassword ? (
              <p className="text-left text-red-600 text-xs mt-1">
                {errors.confirmPassword?.message}
              </p>
            ) : null}
          </div>

          <button
            type="submit"
            className="py-1 border-primary border w-full text-center bg-primary text-white text-sm h-12"
            disabled={loading}
          >
            {loading ? "..." : "Reset Password"}
          </button>
          <div className="text-center mt-4">
            <p className="text-dark text-sm">
              Still need help?{" "}
              <a href="mailto:support@adashi.ng" className="text-primary">
                Contact Support
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

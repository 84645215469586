import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDebounce } from "libs/Hooks";
import axios from "../../../axios";
import { useGroup } from "GroupSavings/hooks/data/useGroup";
import { useParams } from "react-router-dom";
import BankResolveCard from "../BankResolveCard";
import toast from "react-hot-toast";
import correct from "assets/icons/correct.svg";

const formSchema = Yup.object().shape({
  saverId: Yup.string().required("Saver ID is required"),
  amount: Yup.number()
    .required("Transaction amount is required")
    .min(1000, "Transaction amount must be at least NGN1000")
    .max(500_000, "Transaction amount must not be greater than NGN500,000"),
  pin: Yup.string()
    .required("Transaction pin is required")
    .min(4, "Transaction pin must be 4 digits")
    .max(4, "Transaction pin must be 4 digits"),
  // otp: Yup.string()
  //   .required("Otp is required")
  //   .test(
  //     "len",
  //     "OTP Must be exactly 5 characters",
  //     (val) => val.toString().length === 5
  //   ),
  payoutOption: Yup.string().required("Payout option is required"),
});

const Payout = ({ show, togglePayout }) => {
  const [loading, setLoading] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  let params = useParams();

  const [currentSaver, setCurrentSaver] = useState(null);

  useDebounce(() => verifyAccountId(), 1000, [watch("saverId")]);

  const verifyAccountId = async () => {
    if (watch("saverId")?.length < 8) return;

    setLoading(true);
    const saverIdInputEl = document.getElementById("saverId");

    try {
      const response = await axios.get(`/saver/saver/${watch("saverId")}`);
      saverIdInputEl.classList.remove("border-red-500");
      saverIdInputEl.classList.add("border-green-500");
      setCurrentSaver(response.data.data);
      setLoading(false);
    } catch (error) {
      saverIdInputEl.classList.remove("border-green-500");
      saverIdInputEl.classList.add("border-red-500");
      setCurrentSaver(null);
      setLoading(false);
    }
  };

  const { group, refetch } = useGroup(params.id);

  const handlePayout = async (values) => {
    const { payoutOption } = values;
    if (payoutOption === "PAYOUT-TO-AGENT") {
      try {
        setLoading(true);
        const url = `/savings-group/payout/${params.id}`;
        const { data } = await axios.post(url, values);
        toast.success(data.message);
        setLoading(false);
        refetch();
        togglePayout();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    } else {
      toast.error("Payout option not supported yet");
    }
  };

  const handleRequestOtp = async () => {
    try {
      setSendingOtp(true);
      await axios.get(`/saver/send-otp/${currentSaver?.saverId}`);
      setSendingOtp(false);
      setDisableResend(true);
      toast.success("Otp sent successfully");
    } catch (error) {
      setSendingOtp(false);
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.message);
      }
    }
  };

  setTimeout(() => setDisableResend(false), 60000);

  return (
    <Transition appear as={Fragment} show={show}>
      <Dialog
        className="fixed inset-0 z-50 overflow-y-auto text-gray-800"
        onClose={togglePayout}
      >
        <div className="flex items-center justify-center h-screen p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>
          <span aria-hidden="true" className="inline-block align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block p-10 my-10 space-y-5 overflow-hidden text-left align-middle transition-all transform bg-white rounded shadow-xl w-4/5 md:w-2/5 md:min-w-80">
              <Dialog.Title
                as="h2"
                className="text-xl font-medium leading-5 text-left"
              >
                Payout
              </Dialog.Title>
              <form className="mt-6" onSubmit={handleSubmit(handlePayout)}>
                <div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="saverId"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Saver ID
                    </label>
                    <input
                      type="text"
                      className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Saver ID"
                      style={{ transition: "all .15s ease" }}
                      id="saverId"
                      {...register("saverId", { required: true })}
                    />
                    {currentSaver && (
                      <div className="flex justify-between mt-2">
                        <small className="text-xs text-green-600">
                          {`${currentSaver.firstName} ${currentSaver.lastName}`}
                        </small>
                        <img src={correct} alt="..." />
                      </div>
                    )}
                    {errors?.saverId ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.saverId?.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="amount"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Min (NGN 1000)"
                      style={{ transition: "all .15s ease" }}
                      id="amount"
                      defaultValue={group?.amount * group?.slotsNumber || 1000}
                      {...register("amount", { required: true })}
                    />
                    {errors?.amount ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.amount?.message}
                      </p>
                    ) : null}
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="payoutOption"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Payout Option
                    </label>
                    <select
                      className="form-select block w-full px-3 rounded border text-sm border-solid border-gray-300 text-gray-700 bg-white shadow focus:outline-none focus:ring"
                      id="payoutOption"
                      {...register("payoutOption", { required: true })}
                    >
                      <option className="text-sm" value="PAYOUT-TO-AGENT">
                        Payout to agent wallet
                      </option>
                      {/* <option className="text-sm" value="PAYOUT-TO-BANK">
                        Payout to a bank account
                      </option>
                      <option className="text-sm" value="PAYOUT-SAVER-WALLET">
                        Payout to saver wallet
                      </option> */}
                    </select>

                    {errors?.payoutOption ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.payoutOption?.message}
                      </p>
                    ) : null}
                  </div>
                  {watch("payoutOption") === "PAYOUT-TO-BANK" && (
                    <React.Fragment>
                      <BankResolveCard />
                    </React.Fragment>
                  )}
                  {/* <div className="relative w-full mb-5">
                    <label
                      htmlFor="otp"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      OTP
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <input
                          type="text"
                          id="otp"
                          className={
                            "focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md pl-3 sm:text-sm border-gray-300 " +
                            (!currentSaver && "bg-gray-100")
                          }
                          style={{ transition: "all .15s ease" }}
                          {...register("otp", { required: true })}
                          autoComplete="off"
                          disabled={!currentSaver}
                        />
                      </div>
                      {disableResend ? (
                        <button
                          type="button"
                          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary opacity-70 cursor-not-allowed"
                          disabled
                        >
                          ---
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={
                            "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary " +
                            ((!currentSaver || sendingOtp || disableResend) &&
                              "opacity-70 cursor-not-allowed")
                          }
                          disabled={
                            !currentSaver || sendingOtp || disableResend
                          }
                          onClick={handleRequestOtp}
                        >
                          {sendingOtp ? (
                            <span>Sending ...</span>
                          ) : (
                            <span>Get Code</span>
                          )}
                        </button>
                      )}
                    </div>
                    {errors?.otp ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.otp?.type === "typeError"
                          ? "Please enter a valid otp"
                          : errors.otp?.message}
                      </p>
                    ) : null}
                  </div> */}
                  <div className="relative w-full">
                    <label
                      htmlFor="pin"
                      className="text-sm font-medium text-gray-900 block mb-2 text-left"
                    >
                      Transaction Pin
                    </label>
                    <input
                      type="text"
                      className="border border-solid border-gray-300 px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="****"
                      style={{ transition: "all .15s ease" }}
                      id="pin"
                      {...register("pin", { required: true })}
                    />
                    {errors?.pin ? (
                      <p className="text-left text-red-600 text-xs mt-1">
                        {errors.pin?.message}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-center">
                  <button
                    className={
                      "bg-primary text-white capitalize px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-normal " +
                      (loading && "bg-opacity-30")
                    }
                    disabled={loading}
                  >
                    {loading ? "..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Payout;
